import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faInfoCircle,
  faPlay,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ExamDetails() {
  const navigate = useNavigate();

  const [examDetails, setExamDetails] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://newaaas.kolhapurdakshin.com/get_exam_details/",
          {
            user_id: localStorage.getItem("user_id"),
            exam_id: localStorage.getItem("exam_id"),
          }
        );
        if (response.data && response.data.exam_details) {
          setExamDetails(response.data.exam_details);
        }
      } catch (error) {
        console.error("Error fetching exam details:", error);
      }
    };

    fetchData();
  }, []);

  const navigateToFaceVerification = () => {
    navigate("/scheduleExam/ongongExam/sexamDetails/faceVerfcaton");
  };

  const readExamDetails = (instructions, language) => {
    const utterance = new SpeechSynthesisUtterance();
    utterance.text = instructions;

    let selectedVoice;
    switch (language) {
      case "hi":
        selectedVoice = window.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang.startsWith("hi"));
        break;
      case "mr":
        selectedVoice = window.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang.startsWith("mr"));
        break;
      case "kn":
        selectedVoice = window.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang.startsWith("kn"));
        break;
      case "bh":
        selectedVoice = window.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang.startsWith("bh"));
        break;
      case "es":
        selectedVoice = window.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang.startsWith("es"));
        break;
      // Add more cases for other languages as needed
      default:
        selectedVoice = window.speechSynthesis
          .getVoices()
          .find((voice) => voice.lang.startsWith("en"));
    }

    if (selectedVoice) {
      utterance.voice = selectedVoice;
    }

    speechSynthesis.speak(utterance);
  };

  const toggleReading = (instructions) => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
    } else {
      readExamDetails(instructions, selectedLanguage);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}:${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  //style={{ marginTop: "60px" }}
  return (
    <div
      style={{
        background: "linear-gradient(to right, #36D1DC, #5B86E5)",
        marginTop: "60px",
      }}
    >
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <div
          className="main p-3"
          style={{
            flex: "1",
            overflow: "auto",
            background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {examDetails.map((detail, index) => (
            <div
              key={index}
              style={{
                maxWidth: "800px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                transition: "box-shadow 0.3s ease-in-out",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  background: "#f4f4f4",
                  padding: "20px",
                  textAlign: "start", // Aligning text to the start (left)
                }}
              >
                <h2 style={{ color: "#007BFF" }}>{detail.title}</h2>
                <p>
                  <FontAwesomeIcon icon={faClock} />{" "}
                  <strong>Start Date:</strong> {detail.start_date}
                </p>
                <p>
                  <FontAwesomeIcon icon={faClock} />{" "}
                  <strong>Start time:</strong> {detail.time}
                </p>
                <p>
                  <FontAwesomeIcon icon={faClock} /> <strong>Duration:</strong>{" "}
                  {formatTime(detail.duration)}
                </p>
                <p>
                  <strong>
                    <FontAwesomeIcon icon={faInfoCircle} /> Please read the
                    instructions carefully before starting the exam
                  </strong>
                </p>
                <ul style={{ marginBottom: "20px", textAlign: "left" }}>
                  {detail.rules.map((rule, index) => (
                    <li key={index}>{rule}</li>
                  ))}
                </ul>
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-primary mr-2"
                    onClick={() => toggleReading(detail.rules)}
                    style={{
                      transition: "background-color 0.3s ease-in-out",
                      borderRadius: "5px",
                      padding: "10px 20px",
                      cursor: "pointer",
                      outline: "none",
                      border: "none",
                      background: "#007BFF",
                      color: "#fff",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={speechSynthesis.speaking ? faStop : faPlay}
                      className="mr-1"
                      style={{ fontSize: "18px" }}
                    />
                    {speechSynthesis.speaking
                      ? "Stop Reading"
                      : "Read Instructions"}
                  </button>
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-dark mt-3"
                    onClick={navigateToFaceVerification}
                    style={{
                      transition: "background-color 0.3s ease-in-out",
                      borderRadius: "5px",
                      padding: "10px 20px",
                      cursor: "pointer",
                      outline: "none",
                      border: "none",
                      background: "#343a40",
                      color: "#fff",
                    }}
                  >
                    Click to start Verification
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
