import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../Pages/Sidebar";
import AaasNav from "../Pages/AaasNav";

export default function LongAnswerQuz() {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");

  const addQuestion = () => {
    if (currentQuestion.trim() === "") {
      return; // Don't add empty questions
    }

    setQuestions([...questions, { question: currentQuestion, answer: "" }]);
    setCurrentQuestion("");
  };

  const renderQuestions = () => {
    if (questions.length === 0) {
      return <p>No questions added yet.</p>;
    }

    return (
      <div>
        {questions.map((q, index) => (
          <div key={index} className="mb-3">
            <p>
              <strong>Question {index + 1}:</strong> {q.question}
            </p>
            <div className="student-answer-input">
              <label className="form-label">Student's Answer:</label>
              <textarea
                className="form-control"
                value={q.answer}
                onChange={(e) => updateAnswer(index, e.target.value)}
                rows="5"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const updateAnswer = (index, newAnswer) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].answer = newAnswer;
    setQuestions(updatedQuestions);
  };

  return (
    <div>
      <AaasNav />
      <div className="wrapper">
        <Sidebar />
        <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
          <div className="long-answer-quiz-container container mt-5">
            <form>
              <h2 className="quiz-title">Long Answer Quiz</h2>
              <div className="quiz-input">
                <label className="form-label">New Question:</label>
                <textarea
                  className="form-control"
                  value={currentQuestion}
                  onChange={(e) => setCurrentQuestion(e.target.value)}
                  rows="5"
                />
              </div>
              <div className="submit-button mb-3 mt-3">
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={addQuestion}
                >
                  Add Question
                </button>
              </div>
              <div className="questions-list">
                <h3>Questions and Student's Answers:</h3>
                {renderQuestions()}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
