import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Form, FormControl, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrophy,
  faChartBar,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faSearch,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useSpring, animated } from "react-spring";
import jsPDF from "jspdf";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";
import axios from "axios";
import logo from "./Aaaslogo.png"; // Import your logo image
import logos from "./Tdtl.png"; // Import your logo image
import logoss from "./Aaas.png"; // Import your logo image

export default function ShortAnswerQuiz() {
  const [examResults, setExamResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [showAllCertificates, setShowAllCertificates] = useState(false);
  const [fullName, setFullName] = useState(""); // Add fullName state

  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem("user_id");
    const fullNameFromLocalStorage = localStorage.getItem("full_name");

    if (userIdFromLocalStorage) {
      fetchResults(userIdFromLocalStorage);
      setFullName(fullNameFromLocalStorage);
    } else {
      setError("User ID not found in local storage");
    }
  }, []);

  const fetchResults = async (userId) => {
    try {
      const response = await axios.post(
        "https://newaaas.kolhapurdakshin.com/show_result/",
        {
          user_id: userId,
        }
      );

      if (!response.data || !response.data.results) {
        throw new Error("Failed to fetch results");
      }

      setExamResults(response.data.results);
      setError("");
    } catch (error) {
      setError("Error fetching results. Please try again.");
      console.error(error);
    }
  };

  const AnimatedCard = ({ children }) => {
    const styles = useSpring({
      from: { opacity: 0, transform: "scale(0.8)" },
      to: { opacity: 1, transform: "scale(1)" },
    });

    return <animated.div style={styles}>{children}</animated.div>;
  };

  const searchIconStyle = {
    padding: "10px",
    backgroundColor: "#4d90fe",
    color: "white",
    border: "none",
    borderRadius: "0 4px 4px 0",
    cursor: "pointer",
  };

  const downloadAsCsv = () => {
    const csvData = examResults.map(
      (result) =>
        `${result.exam_title},${result.total_marks},${result.marks_obtained},${result.pass_status}`
    );
    const csvContent = "data:text/csv;charset=utf-8," + csvData.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "exam_results.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    const excelData = examResults.map((result) => ({
      ExamName: result.exam_title,
      TotalMarks: result.total_marks,
      ObtainedMarks: result.marks_obtained,
      ResultStatus: result.pass_status,
    }));

    const blob = new Blob([JSON.stringify(excelData)], {
      type: "application/json",
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exam_results.json");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsPdf = () => {
    const pdfDoc = new jsPDF();
    pdfDoc.setProperties({
      title: "Exam Results",
      subject: "Downloaded Exam Results",
      author: "Your Name",
    });

    pdfDoc.text("Exam Results", 20, 20);
    pdfDoc.text("----------------------------------------", 20, 30);

    examResults.forEach((result, index) => {
      const yPos = 40 + index * 20;
      pdfDoc.text(`Exam Name: ${result.exam_title}`, 20, yPos);
      pdfDoc.text(`Total Marks: ${result.total_marks}`, 20, yPos + 10);
      pdfDoc.text(`Obtained Marks: ${result.marks_obtained}`, 20, yPos + 20);
      pdfDoc.text(`Result Status: ${result.pass_status}`, 20, yPos + 30);
      pdfDoc.text("----------------------------------------", 20, yPos + 40);
    });

    pdfDoc.save("exam_results.pdf");
  };

  const filteredResults = showAllCertificates
    ? examResults
    : examResults.slice(Math.max(examResults.length - 3, 0)).reverse();

  const downloadCertificate = (index, fullName) => {
    const examResult = examResults[index];

    if (!examResult) {
      console.error("Exam result not found");
      return;
    }

    const userName = fullName || "User"; // Use a fallback if full name is not available

    // Create a new jsPDF instance
    const pdfDoc = new jsPDF();

    // Add border for certificate
    pdfDoc.setLineWidth(2); // Border width
    pdfDoc.setDrawColor(128, 0, 128); // Purple border color
    pdfDoc.rect(
      10,
      10,
      pdfDoc.internal.pageSize.width - 20,
      pdfDoc.internal.pageSize.height - 20
    ); // Position and size of the border

    // Add logo image on the left side
    const leftLogoWidth = 60; // Adjust width as needed
    const leftLogoHeight = 40; // Adjust height as needed
    const leftX = 20; // Adjust horizontal position as needed
    const leftY = 20; // Adjust vertical position as needed
    pdfDoc.addImage(logoss, "PNG", leftX, leftY, leftLogoWidth, leftLogoHeight);

    // Add logo image in the center
    // const centerLogoWidth = 50;
    // const centerLogoHeight = 30;
    // const centerX = (pdfDoc.internal.pageSize.width - centerLogoWidth) / 2; // Centered horizontally
    // const centerY = 20;
    // pdfDoc.addImage(
    //   logos,
    //   "PNG",
    //   centerX,
    //   centerY,
    //   centerLogoWidth,
    //   centerLogoHeight
    // );

    // Add logo image on the right side
    const rightLogoWidth = 50; // Adjust width as needed
    const rightLogoHeight = 30; // Adjust height as needed
    const rightX = pdfDoc.internal.pageSize.width - rightLogoWidth - 20; // Adjust horizontal position as needed
    const rightY = 20; // Adjust vertical position as needed
    pdfDoc.addImage(
      logos,
      "PNG",
      rightX,
      rightY,
      rightLogoWidth,
      rightLogoHeight
    );

    // Add decorative elements
    pdfDoc.setLineWidth(1);
    pdfDoc.setDrawColor(128, 0, 128); // Purple border color
    pdfDoc.setFillColor(128, 0, 128); // Purple fill color
    pdfDoc.rect(
      20,
      60, // Position below logos
      pdfDoc.internal.pageSize.width - 40,
      1, // Line thickness
      "FD" // Fill and draw
    );

    // Add certificate title
    pdfDoc.setTextColor(128, 0, 128); // Purple text color
    pdfDoc.setFont("times", "bolditalic"); // Different font and style for title
    pdfDoc.setFontSize(24);
    pdfDoc.text(
      `Certificate of Achievement for`, // Certificate title
      pdfDoc.internal.pageSize.width / 2,
      90, // Adjust vertical position as needed
      { align: "center" }
    );
    pdfDoc.setTextColor(0); // Reset text color
    pdfDoc.setFont("helvetica", "normal"); // Reset font

    // Add full name with different font and color
    pdfDoc.setTextColor(255, 0, 0); // Red color for full name
    pdfDoc.setFont("courier", "italic"); // Different font for full name
    pdfDoc.setFontSize(20);
    pdfDoc.text(
      `${userName}`, // Include the full name
      pdfDoc.internal.pageSize.width / 2,
      110, // Adjust vertical position as needed
      { align: "center" }
    );
    pdfDoc.setTextColor(0); // Reset text color
    pdfDoc.setFont("helvetica", "normal"); // Reset font

    // Add exam details paragraph
    pdfDoc.setTextColor(0); // Black text color
    pdfDoc.setFontSize(16);
    const examDetails = `Outstanding performance in the ${examResult.exam_title} examination. With a total\n of ${examResult.total_marks} marks available, ${userName} demonstrated exceptional proficiency\n by securing an impressive score of ${examResult.marks_obtained}. This remarkable achievement\n signifies not only a deep understanding of ${examResult.exam_title} principles but also\n a commitment to excellence and continuous improvement. By\n surpassing the pass threshold, ${userName} has proven themselves capable\n of navigating the intricate intersections of development and operations\n seamlessly. This certificate stands as a testament to their dedication,\n perseverance, and passion for mastering DevOps practices.\n\n\nCongratulations on this well-deserved accomplishment!\nExam Name: ${examResult.exam_title}\nTotal Marks: ${examResult.total_marks}\nMarks Obtained: ${examResult.marks_obtained}\nPass Status: ${examResult.pass_status}`;
    pdfDoc.text(examDetails, 20, 130); // Adjust vertical position as needed

    // Add decorative elements
    pdfDoc.setLineWidth(0.5);
    pdfDoc.setDrawColor(128, 0, 128); // Purple border color
    pdfDoc.line(20, 250, pdfDoc.internal.pageSize.width - 20, 250);

    // Add golden stars for rating
    const stars = "★".repeat(examResult.rating); // Assuming 'rating' property represents the number of stars
    pdfDoc.setFont("times", "bold");
    pdfDoc.setFontSize(20);
    pdfDoc.setTextColor(255, 215, 0); // Golden color
    pdfDoc.text(stars, 20, pdfDoc.internal.pageSize.height - 40);

    // Add footer text
    pdfDoc.setFontSize(12);
    pdfDoc.setTextColor(0); // Black text color
    pdfDoc.text(
      "This certificate is awarded based on the performance in the examination.",
      20,
      pdfDoc.internal.pageSize.height - 20
    );

    // Save the PDF with a unique name
    pdfDoc.save(`${examResult.exam_title}_certificate.pdf`);
  };

  const generateCertificateFunction = (examTitle) => {
    // Add your logic here to generate the certificate content based on the exam title
    return `Certificate for ${examTitle}`; // Example: Generating a simple certificate string
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
          <h2 className="text-start mb-4">All Exam List and Result</h2>
          <Form className="mb-3">
            <div className="d-flex align-items-center flex-wrap">
              <FontAwesomeIcon icon={faSearch} style={searchIconStyle} />
              <FormControl
                type="text"
                placeholder="Search by Exam Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "250px", marginRight: "10px" }}
              />
              <Button
                variant="dark"
                onClick={downloadAsCsv}
                className="mb-2 me-2"
              >
                <FontAwesomeIcon icon={faFileCsv} className="me-2" /> ↓ CSV
              </Button>
              <Button
                variant="dark"
                onClick={downloadAsExcel}
                className="mb-2 me-2"
              >
                <FontAwesomeIcon icon={faFileExcel} className="me-2" /> ↓ Excel
              </Button>
              <Button
                variant="dark"
                onClick={downloadAsPdf}
                className="mb-2 me-2"
              >
                <FontAwesomeIcon icon={faFilePdf} className="me-2" /> ↓ PDF
              </Button>
              <Button
                variant="dark"
                onClick={() => setShowAllCertificates(!showAllCertificates)}
                className="mb-2"
              >
                <FontAwesomeIcon icon={faEye} className="me-2" />
                {showAllCertificates ? "Show Latest 3" : "View All"}
              </Button>
            </div>
          </Form>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Row xs={1} md={2} lg={3} className="g-4">
            {filteredResults.map((result, index) => (
              <Col key={index} xs={12} sm={6} md={4}>
                <AnimatedCard>
                  <Card
                    className={`completed-exam-card ${result.pass_status.toLowerCase()}`}
                    style={{ width: "100%", height: "100%", margin: "15px" }}
                  >
                    <Card.Body style={{ borderRadius: "10px" }}>
                      <div className="exam-info">
                        <span
                          className="exam-name"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          {result.exam_title}
                        </span>
                        <span
                          className="exam-result"
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          {result.pass_status === "Pass" ? (
                            <FontAwesomeIcon icon={faTrophy} />
                          ) : (
                            <FontAwesomeIcon icon={faChartBar} />
                          )}
                          &nbsp;{result.pass_status}
                        </span>
                      </div>
                      <hr />
                      <div className="marks-info" style={{ marginTop: "20px" }}>
                        <span
                          className="total-marks"
                          style={{ fontSize: "16px", color: "#4CAF50" }}
                        >
                          <FontAwesomeIcon icon={faTrophy} />
                          &nbsp;Total Marks: {result.total_marks}
                        </span>
                        <hr style={{ margin: "10px 0" }} />
                        <span
                          className="obtained-marks"
                          style={{
                            fontSize: "16px",
                            marginTop: "10px",
                            color: "#F44336",
                          }}
                        >
                          <FontAwesomeIcon icon={faChartBar} />
                          &nbsp;Obtained Marks: {result.marks_obtained}
                        </span>
                      </div>
                      <Button
                        variant="dark"
                        onClick={() => downloadCertificate(index, fullName)}
                        style={{ marginTop: "10px" }}
                      >
                        Download Certificate
                      </Button>
                    </Card.Body>
                  </Card>
                </AnimatedCard>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}
