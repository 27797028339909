import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
export default function Sidebar() {
  const navigate = useNavigate();
  const navigateToQueson = () => {
    navigate("/queban");
  };
  const navigateToAddExam = () => {
    navigate("/addExam");
  };
  const navigateToExamResult = () => {
    navigate("/examResult");
  };
  const navigateToAllExamLst = () => {
    navigate("/allExamLst");
  };
  const navigateToAllQuesLst = () => {
    navigate("/allQuesLst");
  };
  const navigateToDashboard = () => {
    navigate("/logn/aaasDashboard");
  };
  const navigateToQuestonBanLst = () => {
    navigate("/QuestonBanLst");
  };
  const navigateToExamRequest = () => {
    navigate("/ExamRequest");
  };
  //navigateToExamRequest
  return (
    <div className="">
      <aside
        id="sidebar"
        className="collapsed expand "
        style={{ height: "100%" }}
      >
        <div className="d-flex justify-content-between">
          <div className="sidebar-logo">{/* <a href="#">Aaas</a> */}</div>
          <button
            className="toggle-btn"
            type="button"
            onClick={() =>
              document.querySelector("#sidebar").classList.toggle("expand")
            }
            style={{ background: "transparent", color: "white" }}
          >
            <i className="lni lni-menu"></i>
          </button>
        </div>

        <ul className="sidebar-nav">
          <li className="sidebar-item">
            <a href="#" className="sidebar-link" onClick={navigateToDashboard}>
              <i className="lni lni-home"></i>

              <span> Dashboard</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#auth"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-protection"></i>
              <span>Schedule Exam</span>
            </a>
            <ul
              id="auth"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToAddExam}
                >
                  Add Exam
                </a>
              </li>
              <li className="sidebar-item text-start ps-4 ">
                <a
                  href="#"
                  className="sidebar-link"
                  onClick={navigateToAllExamLst}
                >
                  All Exam List
                </a>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#auths"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-question-circle"></i>
              <span>Question Bank Setup</span>
            </a>
            <ul
              id="auths"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link  "
                  onClick={navigateToQueson}
                >
                  Add Question
                </a>
              </li>
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToAllQuesLst}
                >
                  All Questions List
                </a>
              </li>
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToQuestonBanLst}
                >
                  All Questions Categories
                </a>
              </li>
            </ul>
          </li>
          {/* <li className="sidebar-item">
            <a href="#" className="sidebar-link" onClick={navigateToQueson}>
              <i className="lni lni-question-circle"></i>
              <span>Question Bank Setup</span>
            </a>
          </li> */}
          <li className="sidebar-item">
            <a href="#" className="sidebar-link" onClick={navigateToExamResult}>
              <i className="lni lni-checkmark-circle"></i>{" "}
              <span>Exam Result</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link"
              onClick={navigateToExamRequest}
            >
              <i className="lni lni-checkmark-circle"></i>{" "}
              <span>Student Exam Request</span>
            </a>
          </li>
        </ul>
        <div className="sidebar-footer">
          <a href="#" className="sidebar-link">
            <i className="lni lni-exit"></i>
            <span>Logout</span>
          </a>
        </div>
      </aside>
    </div>
  );
}
// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// export default function Sidebar() {
//   const navigate = useNavigate();
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
//   const [isMobileView, setIsMobileView] = useState(false);

//   const navigateToQueson = () => {
//     navigate("/queban");
//   };

//   const navigateToAddExam = () => {
//     navigate("/addExam");
//   };

//   const navigateToExamResult = () => {
//     navigate("/examResult");
//   };

//   const navigateToAllExamLst = () => {
//     navigate("/allExamLst");
//   };

//   const navigateToAllQuesLst = () => {
//     navigate("/allQuesLst");
//   };

//   const navigateToDashboard = () => {
//     navigate("/logn/aaasDashboard");
//   };

//   const navigateToQuestonBanLst = () => {
//     navigate("/QuestonBanLst");
//   };

//   const navigateToExamRequest = () => {
//     navigate("/ExamRequest");
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobileView(true);
//         setIsSidebarOpen(false);
//       } else {
//         setIsMobileView(false);
//         setIsSidebarOpen(true);
//       }
//     };

//     handleResize();

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return (
//     <div className="">
//       <aside
//         id="sidebar"
//         className={`collapsed ${isSidebarOpen ? "expand" : ""}`}
//         style={{ height: "100%" }}
//       >
//         <div className="d-flex justify-content-between">
//           <div className="sidebar-logo">{/* <a href="#">Your Logo</a> */}</div>
//           {isMobileView && (
//             <button
//               className="toggle-btn"
//               type="button"
//               style={{ background: "transparent", color: "white" }}
//               onClick={() => setIsSidebarOpen((prev) => !prev)}
//             >
//               <i className="lni lni-menu"></i>
//             </button>
//           )}
//         </div>

//         <ul className="sidebar-nav">
//           <li className="sidebar-item">
//             <a href="#" className="sidebar-link" onClick={navigateToDashboard}>
//               <i className="lni lni-home"></i>
//               <span> Dashboard</span>
//             </a>
//           </li>
//           <li className="sidebar-item">
//             <a
//               href="#"
//               className="sidebar-link collapsed has-dropdown"
//               data-bs-toggle="collapse"
//               data-bs-target="#auth"
//               aria-expanded="false"
//               aria-controls="auth"
//             >
//               <i className="lni lni-protection"></i>
//               <span>Schedule Exam</span>
//             </a>
//             <ul
//               id="auth"
//               className="sidebar-dropdown list-unstyled collapse text-center"
//               data-bs-parent="#sidebar"
//             >
//               <li className="sidebar-item text-start ps-4">
//                 <a
//                   href="#"
//                   className="sidebar-link"
//                   onClick={navigateToAddExam}
//                 >
//                   Add Exam
//                 </a>
//               </li>
//               <li className="sidebar-item text-start ps-4">
//                 <a
//                   href="#"
//                   className="sidebar-link"
//                   onClick={navigateToAllExamLst}
//                 >
//                   All Exam List
//                 </a>
//               </li>
//             </ul>
//           </li>
//           <li className="sidebar-item">
//             <a
//               href="#"
//               className="sidebar-link collapsed has-dropdown"
//               data-bs-toggle="collapse"
//               data-bs-target="#auths"
//               aria-expanded="false"
//               aria-controls="auth"
//             >
//               <i className="lni lni-question-circle"></i>
//               <span>Question Bank Setup</span>
//             </a>
//             <ul
//               id="auths"
//               className="sidebar-dropdown list-unstyled collapse text-center"
//               data-bs-parent="#sidebar"
//             >
//               <li className="sidebar-item text-start ps-4">
//                 <a href="#" className="sidebar-link" onClick={navigateToQueson}>
//                   Add Question
//                 </a>
//               </li>
//               <li className="sidebar-item text-start ps-4">
//                 <a
//                   href="#"
//                   className="sidebar-link"
//                   onClick={navigateToAllQuesLst}
//                 >
//                   All Questions List
//                 </a>
//               </li>
//               <li className="sidebar-item text-start ps-4">
//                 <a
//                   href="#"
//                   className="sidebar-link"
//                   onClick={navigateToQuestonBanLst}
//                 >
//                   All Questions Categories
//                 </a>
//               </li>
//             </ul>
//           </li>
//           <li className="sidebar-item">
//             <a href="#" className="sidebar-link" onClick={navigateToExamResult}>
//               <i className="lni lni-checkmark-circle"></i>{" "}
//               <span>Exam Result</span>
//             </a>
//           </li>
//           <li className="sidebar-item">
//             <a
//               href="#"
//               className="sidebar-link"
//               onClick={navigateToExamRequest}
//             >
//               <i className="lni lni-checkmark-circle"></i>{" "}
//               <span>Student Exam Request</span>
//             </a>
//           </li>
//         </ul>
//         <div className="sidebar-footer">
//           <a href="#" className="sidebar-link">
//             <i className="lni lni-exit"></i>
//             <span>Logout</span>
//           </a>
//         </div>
//       </aside>
//     </div>
//   );
// }
