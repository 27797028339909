// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import "bootstrap/dist/css/bootstrap.min.css";
// import bregster from "../Pages/bregster.png";
// import VerificationInput from "react-verification-input";
// import axios from "axios";

// const ForgotPassword = () => {
//   const [email, setEmail] = useState("");
//   const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
//   const [verificationCode, setVerificationCode] = useState("");
//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         "https://newaaas.kolhapurdakshin.com/email_verification/",
//         { email }
//       );
//       if (response.status === 200) {
//         setIsVerificationCodeSent(true);
//         Swal.fire({
//           icon: "success",
//           title: "Verification Code Sent!",
//           text: "Reset Your Password.",
//           confirmButtonText: "Check Email",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             // You can add redirection logic to check email
//             // For example: window.location.href = 'https://your-email-provider.com';
//           }
//         });

//         // Extract user_id from the response
//         const { user_id } = response.data;
//         localStorage.setItem("user_id", user_id);

//         // Pass user_id to the state
//         setEmail(user_id);
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text:
//             response.data.error ||
//             "Failed to send reset password email. Please try again later.",
//         });
//       }
//     } catch (error) {
//       console.error("Error sending reset password email:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to send reset password email. Please try again later.",
//       });
//     }
//   };

//   // Now, handleSubmit sets the email state with the user_id received from the response

//   const handleVerifyCodeSubmit = async () => {
//     try {
//       const response = await axios.post("https://newaaas.kolhapurdakshin.com/verify_code/", {
//         user_id: email,
//         otp: verificationCode,
//       });
//       if (response.status === 200) {
//         navigate("/resetpassword");
//         // Show SweetAlert notification for successful verification
//         Swal.fire({
//           icon: "success",
//           title: "Verification Successful!",
//           text: "Your verification code has been successfully verified.",
//           confirmButtonText: "OK",
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Verification Failed!",
//           text:
//             response.data.error || "Failed to verify code. Please try again.",
//         });
//       }
//     } catch (error) {
//       console.error("Error verifying code:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to verify code. Please try again later.",
//       });
//     }
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col-md-7 d-none d-md-block">
//           <img
//             src={bregster}
//             alt="Your Image Alt Text"
//             className="img-fluid"
//             style={{ height: "100%" }}
//           />
//         </div>
//         <div className="col-md-5 d-flex justify-content-center ">
//           <div className="main p-4  rounded shadow">
//             <div className="" style={{ maxWidth: "450px", margin: "0 auto" }}>
//               <div
//                 className="text-center mb-1 "
//                 style={{
//                   color: "dodgerblue",
//                   fontWeight: "bold",
//                   fontSize: "2.5rem",
//                 }}
//               >
//                 {isVerificationCodeSent
//                   ? "Enter Verification Code"
//                   : "Forgot Password"}
//               </div>
//               {isVerificationCodeSent ? (
//                 <form
//                   onSubmit={(e) => {
//                     e.preventDefault();
//                     handleVerifyCodeSubmit();
//                   }}
//                 >
//                   <div className="mb-3">
//                     <p style={{ color: "red" }}>
//                       A verification code has been sent to your email address.
//                       Please check your inbox and fill Code below
//                     </p>

//                     <VerificationInput
//                       length={6} // Set the length of the verification code
//                       placeholder="" // Placeholder for each input
//                       onChange={(value) => setVerificationCode(value)} // Handle change event
//                     />
//                   </div>
//                   <button
//                     type="submit"
//                     className="btn btn-dark btn-block"
//                     style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
//                   >
//                     Verify Code
//                   </button>
//                 </form>
//               ) : (
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="email"
//                       className="form-label"
//                       style={{ fontWeight: "bold", fontSize: "1.3rem" }}
//                     >
//                       Email address
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="email"
//                       placeholder="Enter your email"
//                       value={email}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <button
//                     type="submit"
//                     className="btn btn-dark btn-block"
//                     style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
//                   >
//                     Send Verification Code
//                   </button>
//                 </form>
//               )}
//               <div className="mt-3 text-center">
//                 <Link to="/login">Remembered your password? Log in here</Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgotPassword;
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import bregster from "../Pages/bregster.png";
import VerificationInput from "react-verification-input";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (isVerificationCodeSent) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isVerificationCodeSent]);

  useEffect(() => {
    if (timer === 0) {
      // Timer expired, reset the state
      setIsVerificationCodeSent(false);
      setTimer(300); // Reset timer to 5 minutes
    }
  }, [timer]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://newaaas.kolhapurdakshin.com/email_verification/",
        { email }
      );
      if (response.status === 200) {
        setIsVerificationCodeSent(true);
        Swal.fire({
          icon: "success",
          title: "Verification Code Sent!",
          text: "Reset Your Password.",
          confirmButtonText: "Check Email",
        }).then((result) => {
          if (result.isConfirmed) {
            // You can add redirection logic to check email
            // For example: window.location.href = 'https://your-email-provider.com';
          }
        });

        // Extract user_id from the response
        const { user_id } = response.data;
        localStorage.setItem("user_id", user_id);

        // Pass user_id to the state
        setEmail(user_id);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            response.data.error ||
            "Failed to send reset password email. Please try again later.",
        });
      }
    } catch (error) {
      console.error("Error sending reset password email:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to send reset password email. Please try again later.",
      });
    }
  };

  const handleVerifyCodeSubmit = async () => {
    try {
      const response = await axios.post(
        "https://newaaas.kolhapurdakshin.com/verify_code/",
        {
          user_id: email,
          otp: verificationCode,
        }
      );
      if (response.status === 200) {
        navigate("/resetpassword");
        // Show SweetAlert notification for successful verification
        Swal.fire({
          icon: "success",
          title: "Verification Successful!",
          text: "Your verification code has been successfully verified.",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Verification Failed!",
          text:
            response.data.error || "Failed to verify code. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to verify code. Please try again later.",
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7 d-none d-md-block">
          <img
            src={bregster}
            alt="Your Image Alt Text"
            className="img-fluid"
            style={{ height: "100%" }}
          />
        </div>
        <div className="col-md-5 d-flex justify-content-center ">
          <div className="main p-4  rounded shadow">
            <div className="" style={{ maxWidth: "450px", margin: "0 auto" }}>
              <div
                className="text-center mb-1 "
                style={{
                  color: "dodgerblue",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                }}
              >
                {isVerificationCodeSent
                  ? "Enter Verification Code"
                  : "Forgot Password"}
              </div>
              {isVerificationCodeSent ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleVerifyCodeSubmit();
                  }}
                >
                  <div className="mb-3">
                    <p style={{ color: "red" }}>
                      A verification code has been sent to your email address.
                      Please check your inbox and fill Code below.{" "}
                    </p>
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Time left: {Math.floor(timer / 60)}:
                      {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                    </p>

                    <VerificationInput
                      length={6} // Set the length of the verification code
                      placeholder="" // Placeholder for each input
                      onChange={(value) => setVerificationCode(value)} // Handle change event
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-dark btn-block"
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                  >
                    Verify Code
                  </button>
                </form>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className="form-label"
                      style={{ fontWeight: "bold", fontSize: "1.3rem" }}
                    >
                      Email address &nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-dark btn-block"
                    style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
                  >
                    Send Verification Code
                  </button>
                </form>
              )}
              <div className="mt-3 text-center">
                <Link to="/login">Remembered your password? Log in here</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import "bootstrap/dist/css/bootstrap.min.css";
// import bregster from "../Pages/bregster.png";
// import VerificationInput from "react-verification-input";
// import axios from "axios";

// const ForgotPassword = () => {
//   const [email, setEmail] = useState("");
//   const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
//   const [verificationCode, setVerificationCode] = useState("");
//   const [timer, setTimer] = useState(300); // 5 minutes in seconds
//   const navigate = useNavigate();

//   useEffect(() => {
//     let interval;
//     if (isVerificationCodeSent) {
//       interval = setInterval(() => {
//         setTimer((prevTimer) => prevTimer - 1);
//       }, 1000);
//     }

//     return () => clearInterval(interval);
//   }, [isVerificationCodeSent]);

//   useEffect(() => {
//     if (timer === 0) {
//       // Timer expired, reset the state
//       setIsVerificationCodeSent(false);
//       setTimer(300); // Reset timer to 5 minutes
//     }
//   }, [timer]);

//   const handleChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         "https://newaaas.kolhapurdakshin.com/email_verification/",
//         { email }
//       );
//       if (response.status === 200) {
//         setIsVerificationCodeSent(true);
//         Swal.fire({
//           icon: "success",
//           title: "Verification Code Sent!",
//           text: "Reset Your Password.",
//           confirmButtonText: "Check Email",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             // You can add redirection logic to check email
//             // For example: window.location.href = 'https://your-email-provider.com';
//           }
//         });

//         // Extract user_id from the response
//         const { user_id } = response.data;
//         localStorage.setItem("user_id", user_id);

//         // Pass user_id to the state
//         setEmail(user_id);
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text:
//             response.data.error ||
//             "Failed to send reset password email. Please try again later.",
//         });
//       }
//     } catch (error) {
//       console.error("Error sending reset password email:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to send reset password email. Please try again later.",
//       });
//     }
//   };

//   const handleVerifyCodeSubmit = async () => {
//     try {
//       const response = await axios.post("https://newaaas.kolhapurdakshin.com/verify_code/", {
//         user_id: email,
//         otp: verificationCode,
//       });
//       if (response.status === 200) {
//         navigate("/resetpassword");
//         // Show SweetAlert notification for successful verification
//         Swal.fire({
//           icon: "success",
//           title: "Verification Successful!",
//           text: "Your verification code has been successfully verified.",
//           confirmButtonText: "OK",
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Verification Failed!",
//           text:
//             response.data.error || "Failed to verify code. Please try again.",
//         });
//       }
//     } catch (error) {
//       console.error("Error verifying code:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to verify code. Please try again later.",
//       });
//     }
//   };

//   const handleResendCode = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         "https://newaaas.kolhapurdakshin.com/email_verification/",
//         { email }
//       );
//       if (response.status === 200) {
//         setIsVerificationCodeSent(true);
//         Swal.fire({
//           icon: "success",
//           title: "Verification Code Sent!",
//           text: "Reset Your Password.",
//           confirmButtonText: "Check Email",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             // You can add redirection logic to check email
//             // For example: window.location.href = 'https://your-email-provider.com';
//           }
//         });

//         // Extract user_id from the response
//         const { user_id } = response.data;
//         localStorage.setItem("user_id", user_id);

//         // Pass user_id to the state
//         setEmail(user_id);
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text:
//             response.data.error ||
//             "Failed to send reset password email. Please try again later.",
//         });
//       }
//     } catch (error) {
//       console.error("Error sending reset password email:", error);
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Failed to send reset password email. Please try again later.",
//       });
//     }
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col-md-7 d-none d-md-block">
//           <img
//             src={bregster}
//             alt="Your Image Alt Text"
//             className="img-fluid"
//             style={{ height: "100%" }}
//           />
//         </div>
//         <div className="col-md-5 d-flex justify-content-center ">
//           <div className="main p-4  rounded shadow">
//             <div className="" style={{ maxWidth: "450px", margin: "0 auto" }}>
//               <div
//                 className="text-center mb-1 "
//                 style={{
//                   color: "dodgerblue",
//                   fontWeight: "bold",
//                   fontSize: "2.5rem",
//                 }}
//               >
//                 {isVerificationCodeSent
//                   ? "Enter Verification Code"
//                   : "Forgot Password"}
//               </div>
//               {isVerificationCodeSent ? (
//                 <form
//                   onSubmit={(e) => {
//                     e.preventDefault();
//                     handleVerifyCodeSubmit();
//                   }}
//                 >
//                   <div className="mb-3">
//                     <p style={{ color: "red" }}>
//                       A verification code has been sent to your email address.
//                       Please check your inbox and fill Code below.{" "}
//                     </p>
//                     <p style={{ color: "red", fontWeight: "bold" }}>
//                       Time left: {Math.floor(timer / 60)}:
//                       {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
//                     </p>

//                     <VerificationInput
//                       length={6} // Set the length of the verification code
//                       placeholder="" // Placeholder for each input
//                       onChange={(value) => setVerificationCode(value)} // Handle change event
//                     />
//                   </div>
//                   <button
//                     type="submit"
//                     className="btn btn-dark btn-block"
//                     style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
//                   >
//                     Verify Code
//                   </button>
//                   <button
//                     type="button"
//                     className="btn btn-link btn-block"
//                     onClick={handleResendCode}
//                     style={{ textDecoration: "underline" }}
//                   >
//                     Resend Code
//                   </button>
//                 </form>
//               ) : (
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="email"
//                       className="form-label"
//                       style={{ fontWeight: "bold", fontSize: "1.3rem" }}
//                     >
//                       Email address
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="email"
//                       placeholder="Enter your email"
//                       value={email}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>
//                   <button
//                     type="submit"
//                     className="btn btn-dark btn-block"
//                     style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)" }}
//                   >
//                     Send Verification Code
//                   </button>
//                 </form>
//               )}
//               <div className="mt-3 text-center">
//                 <Link to="/login">Remembered your password? Log in here</Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgotPassword;
