import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import AaasNav from "./AaasNav";
import Sidebar from "./Sidebar";

const studentdashboard = () => {
  return (
    <div>
      <AaasNav />
      <div className="wrapper">
        <Sidebar />
        <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
          <div className="container mt-5"></div>
        </div>
      </div>
    </div>
  );
};

export default studentdashboard;
