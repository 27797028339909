import React, { useState } from "react";
import AaasNav from "../Pages/AaasNav";
import Sidebar from "../Pages/Sidebar";

const ManualQuizPage = () => {
  const [questions, setQuestions] = useState([
    {
      type: "mcq",
      question: "",
      options: ["", "", "", ""],
    },
    {
      type: "paragraph",
      question: "",
    },
    {
      type: "text",
      question: "",
    },
  ]);

  const handleQuestionChange = (index, type, value) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        [type]: value,
      };
      return updatedQuestions;
    });
  };

  const renderQuestionInputs = () => {
    return questions.map((question, index) => (
      <div key={index} className="mb-3">
        <label htmlFor={`question${index}`} className="form-label">{`Question ${
          index + 1
        }`}</label>
        <input
          type="text"
          id={`question${index}`}
          className="form-control"
          placeholder={`Enter your ${question.type} question`}
          value={question.question}
          onChange={(e) =>
            handleQuestionChange(index, "question", e.target.value)
          }
        />

        {question.type === "mcq" && (
          <div>
            <label className="form-label">Options:</label>
            {question.options.map((option, optionIndex) => (
              <input
                key={optionIndex}
                type="text"
                className="form-control"
                placeholder={`Option ${optionIndex + 1}`}
                value={option}
                onChange={(e) =>
                  handleQuestionChange(index, "options", [
                    ...question.options.slice(0, optionIndex),
                    e.target.value,
                    ...question.options.slice(optionIndex + 1),
                  ])
                }
              />
            ))}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div>
      <AaasNav />
      <div className="wrapper">
        <Sidebar />
        <div className="main p-3">
          <h2>Manual Quiz Setup</h2>
          <form>
            {renderQuestionInputs()}
            <button type="submit" className="btn btn-dark">
              Save Quiz
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManualQuizPage;
