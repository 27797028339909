import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Form, FormControl, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrophy,
  faChartBar,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useSpring, animated } from "react-spring";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";

export default function ShortAnswerQuz() {
  const [examResults, setExamResults] = useState([
    {
      id: 1,
      examName: "Anant Prabhu Code Race - Exam - ",
      totalMarks: 80,
      obtainedMarks: 75,
      resultStatus: "Pass",
    },
    {
      id: 2,
      examName: "Code Master Blaster - Exam - ",
      totalMarks: 100,
      obtainedMarks: 35,
      resultStatus: "Bro You Fail the Exam",
    },
    // Add more results as needed
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const AnimatedCard = ({ children }) => {
    const styles = useSpring({
      from: { opacity: 0, transform: "scale(0.8)" },
      to: { opacity: 1, transform: "scale(1)" },
    });

    return <animated.div style={styles}>{children}</animated.div>;
  };

  const searchIconStyle = {
    padding: "10px",
    backgroundColor: "#4d90fe",
    color: "white",
    border: "none",
    borderRadius: "0 4px 4px 0",
    cursor: "pointer",
  };

  const filteredResults = examResults.filter((result) =>
    result.examName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
          <h2 className="text-start mb-4">All Exam List and Result</h2>
          <Form className="mb-3">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faSearch} style={searchIconStyle} />
              <FormControl
                type="text"
                placeholder="Search by Exam Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "250px" }}
              />
            </div>
          </Form>
          <Row xs={1} md={2} lg={3} className="g-4">
            {filteredResults.map((result) => (
              <Col key={result.id}>
                <AnimatedCard>
                  <Card
                    className={`completed-exam-card ${result.resultStatus.toLowerCase()}`}
                    style={{ width: "300px", height: "200px", margin: "15px" }}
                  >
                    <Card.Body style={{ borderRadius: "10px" }}>
                      <div className="exam-info">
                        <span
                          className="exam-name"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          {result.examName}
                        </span>
                        <span
                          className="exam-result"
                          style={{ fontSize: "16px", marginTop: "10px" }}
                        >
                          {result.resultStatus === "Pass" ? (
                            <FontAwesomeIcon icon={faTrophy} />
                          ) : (
                            <FontAwesomeIcon icon={faChartBar} />
                          )}
                          &nbsp;{result.resultStatus}
                        </span>
                      </div>
                      <hr />
                      <div className="marks-info" style={{ marginTop: "20px" }}>
                        <span
                          className="total-marks"
                          style={{ fontSize: "16px", color: "#4CAF50" }}
                        >
                          <FontAwesomeIcon icon={faTrophy} />
                          &nbsp;Total Marks: {result.totalMarks}
                        </span>
                        <hr style={{ margin: "10px 0" }} />
                        <span
                          className="obtained-marks"
                          style={{
                            fontSize: "16px",
                            marginTop: "10px",
                            color: "#F44336",
                          }}
                        >
                          <FontAwesomeIcon icon={faChartBar} />
                          &nbsp;Obtained Marks: {result.obtainedMarks}
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                </AnimatedCard>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}
