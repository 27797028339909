// import React, { useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import axios from "axios"; // Import Axios for making HTTP requests

// export default function TrueFalseQuiz({ category, sub_category, type }) {
//   const [questions, setQuestions] = useState([]);
//   const [currentQuestion, setCurrentQuestion] = useState("");
//   const [currentAnswer, setCurrentAnswer] = useState(true);

//   const addQuestion = () => {
//     if (currentQuestion.trim() === "") {
//       return; // Don't add empty questions
//     }

//     // Prepare data for the POST request
//     const data = {
//       description: currentQuestion,
//       marks: 0, // You might want to add marks input in your UI
//       category: category,
//       sub_category: sub_category,
//       type: type,

//       option1: "True",
//       option2: "False",
//       correct_option: currentAnswer ? "True" : "False",
//     };

//     // Make an HTTP POST request to the backend
//     axios
//       .post("https://newaaas.kolhapurdakshin.com/true_false/", data)
//       .then((response) => {
//         console.log(response.data); // Log success message
//         // Add the question to the state if it's successfully added to the backend
//         setQuestions([
//           ...questions,
//           { question: currentQuestion, answer: currentAnswer },
//         ]);
//         setCurrentQuestion("");
//         setCurrentAnswer(true); // Reset answer to true for the next question
//       })
//       .catch((error) => {
//         console.error("Error adding question:", error); // Log error if any
//       });
//   };

//   const renderQuestions = () => {
//     if (questions.length === 0) {
//       return <p className="text-muted">No questions added yet.</p>;
//     }

//     return (
//       <div>
//         {questions.map((q, index) => (
//           <div key={index} className="card mb-3">
//             <div className="card-body">
//               <h5 className="card-title">Question {index + 1}:</h5>
//               <p className="card-text">{q.question}</p>
//               <p className="card-text">
//                 <strong>Answer:</strong> {q.answer ? "True" : "False"}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   return (
//     <div className="container mt-5">
//       <h2 className="text-center mb-4">True or False Quiz</h2>
//       <div className="row">
//         <div className="col-md-6">
//           <form>
//             <div className="mb-3 text-start">
//               <label className="form-label">New Question:</label>
//               <textarea
//                 className="form-control"
//                 value={currentQuestion}
//                 onChange={(e) => setCurrentQuestion(e.target.value)}
//               />
//             </div>
//             <div className="mb-3 text-start">
//               <label className="form-label">Answer:</label>
//               <div className="form-check">
//                 <input
//                   type="radio"
//                   className="form-check-input"
//                   value={true}
//                   checked={currentAnswer === true}
//                   onChange={() => setCurrentAnswer(true)}
//                 />
//                 <label className="form-check-label">True</label>
//               </div>
//               <div className="form-check">
//                 <input
//                   type="radio"
//                   className="form-check-input"
//                   value={false}
//                   checked={currentAnswer === false}
//                   onChange={() => setCurrentAnswer(false)}
//                 />
//                 <label className="form-check-label">False</label>
//               </div>
//             </div>
//             <div className="mb-3">
//               <button
//                 type="button"
//                 className="btn btn-dark text-start"
//                 onClick={addQuestion}
//               >
//                 Add Question
//               </button>
//             </div>
//           </form>
//         </div>
//         {/* <div className="col-md-6">
//           <div className="mb-3">
//             <h3 className="text-start">Questions and Answers:</h3>
//             {renderQuestions()}
//           </div>
//         </div> */}
//       </div>
//     </div>
//   );
// }
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios"; // Import Axios for making HTTP requests

export default function TrueFalseQuiz({ category, sub_category, type }) {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState(true);
  const [currentMarks, setCurrentMarks] = useState(0); // State for marks

  const addQuestion = () => {
    if (currentQuestion.trim() === "") {
      return; // Don't add empty questions
    }

    // Prepare data for the POST request
    const data = {
      description: currentQuestion,
      marks: currentMarks, // Use the currentMarks state
      category: category,
      sub_category: sub_category,
      type: type,

      option1: "True",
      option2: "False",
      correct_option: currentAnswer ? "True" : "False",
    };

    // Make an HTTP POST request to the backend
    axios
      .post("https://newaaas.kolhapurdakshin.com/true_false/", data)
      .then((response) => {
        console.log(response.data); // Log success message
        // Add the question to the state if it's successfully added to the backend
        setQuestions([
          ...questions,
          {
            question: currentQuestion,
            answer: currentAnswer,
            marks: currentMarks,
          },
        ]);
        setCurrentQuestion("");
        setCurrentAnswer(true); // Reset answer to true for the next question
        setCurrentMarks(0); // Reset marks for the next question
      })
      .catch((error) => {
        console.error("Error adding question:", error); // Log error if any
      });
  };

  const renderQuestions = () => {
    if (questions.length === 0) {
      return <p className="text-muted">No questions added yet.</p>;
    }

    return (
      <div>
        {questions.map((q, index) => (
          <div key={index} className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Question {index + 1}:</h5>
              <p className="card-text">{q.question}</p>
              <p className="card-text">
                <strong>Answer:</strong> {q.answer ? "True" : "False"}
              </p>
              <p className="card-text">
                <strong>Marks:</strong> {q.marks}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">True or False Quiz</h2>
      <div className="row">
        <div className="col-md-6">
          <form>
            <div className="mb-3 text-start">
              <label className="form-label">New Question:</label>
              <textarea
                className="form-control"
                value={currentQuestion}
                onChange={(e) => setCurrentQuestion(e.target.value)}
              />
            </div>
            <div className="mb-3 text-start">
              <label className="form-label">Answer:</label>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={true}
                  checked={currentAnswer === true}
                  onChange={() => setCurrentAnswer(true)}
                />
                <label className="form-check-label">True</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  value={false}
                  checked={currentAnswer === false}
                  onChange={() => setCurrentAnswer(false)}
                />
                <label className="form-check-label">False</label>
              </div>
            </div>
            <div className="mb-3 text-start">
              <label className="form-label">Marks:</label>
              <input
                type="number"
                className="form-control"
                value={currentMarks}
                onChange={(e) => setCurrentMarks(parseInt(e.target.value))}
              />
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-dark text-start"
                onClick={addQuestion}
              >
                Add Question
              </button>
            </div>
          </form>
        </div>
        {/* <div className="col-md-6">
          <div className="mb-3">
            <h3 className="text-start">Questions and Answers:</h3>
            {renderQuestions()}
          </div>
        </div> */}
      </div>
    </div>
  );
}
