// import React, { useState, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCamera,
//   faCheckCircle,
//   faStopCircle,
// } from "@fortawesome/free-solid-svg-icons";
// import Webcam from "react-webcam";
// import AaasStudentNav from "../Pages/AaasStudentNav";
// import SDSdebar from "../Pages/SDSdebar";
// import Swal from "sweetalert2";

// export default function FaceVerification() {
//   const [capturedImages, setCapturedImages] = useState([]);
//   const [capturedCount, setCapturedCount] = useState(0);
//   const webcamRef = useRef(null);
//   const [isCameraOn, setIsCameraOn] = useState(true);

//   const handleCapture = () => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setCapturedImages((prevImages) => [...prevImages, imageSrc]);
//     setCapturedCount((prevCount) => prevCount + 1);

//     if (capturedCount === 2) {
//       handleStopCamera();
//     }
//   };

//   const handleStopCamera = () => {
//     setIsCameraOn(false);
//   };

//   const handleSavePhotos = async () => {
//     const user_id = localStorage.getItem("user_id");
//     const exam_id = localStorage.getItem("exam_id");
//     if (!user_id || !exam_id) {
//       console.error("User ID or Exam ID not found in local storage");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("user_id", user_id);
//     formData.append("exam_id", exam_id);
//     capturedImages.forEach((image, index) => {
//       formData.append(`photo${index + 1}_base64`, image);
//     });

//     try {
//       const response = await fetch(
//         "https://newaaas.kolhapurdakshin.com/save_photos/",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );
//       const data = await response.json();
//       console.log(data);

//       if (data.verified) {
//         Swal.fire({
//           icon: "success",
//           title: "Verification Successful!",
//           text: "Your photos have been saved successfully.",
//           confirmButtonText: "Ready to Start Exam! ->",
//         }).then(() => {
//           window.location.href =
//             "/scheduleExam/ongongExam/sexamDetails/faceVerfcaton/startExam";
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Verification Failed!",
//           text: "Your photos could not be verified. Please try again.",
//           confirmButtonText: "Try Again",
//         });
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   return (
//     <div style={{ marginTop: "60px" }}>
//       <AaasStudentNav />
//       <div className="wrapper">
//         <SDSdebar />

//         <div
//           className="main p-3"
//           style={{
//             flex: "1",
//             overflow: "auto",
//             background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
//           }}
//         >
//           <div className="row">
//             <div className="col-md-6">
//               <div className="card p-4">
//                 <h2 className="card-title mb-4">Face Verification</h2>
//                 <p className="text-danger card-text">
//                   Please capture <strong>Three photos</strong> of your face to
//                   proceed with the verification process.
//                 </p>

//                 <div className="mb-3">
//                   {isCameraOn ? (
//                     <>
//                       <div
//                         className="text-start mb-3"
//                         style={{ position: "relative" }}
//                       >
//                         <div className="text-start">
//                           <div>
//                             <Webcam
//                               audio={false}
//                               ref={webcamRef}
//                               screenshotFormat="image/jpeg"
//                               style={{
//                                 width: "100%",
//                                 maxWidth: "400px",
//                                 height: "100%",
//                                 border: "1px solid black",
//                                 position: "relative",
//                                 zIndex: 1,
//                               }}
//                             />
//                           </div>
//                           <div
//                             className="text-center"
//                             style={{
//                               top: 0,
//                               left: 0,
//                               width: "100%",
//                               maxWidth: "400px",
//                               height: "100%",
//                               position: "absolute",
//                               backgroundImage:
//                                 "url('https://cdn.dribbble.com/users/3496409/screenshots/7749099/scanner.gif')",
//                               backgroundSize: "cover",
//                               opacity: 0.3,
//                               zIndex: 2,
//                             }}
//                           ></div>
//                         </div>
//                       </div>
//                       <div className="text-center mb-3">
//                         <button
//                           className="btn btn-primary"
//                           onClick={handleCapture}
//                         >
//                           <FontAwesomeIcon icon={faCamera} /> Capture Photo
//                         </button>
//                       </div>
//                       <div className="text-center">
//                         <button
//                           className="btn btn-danger"
//                           onClick={handleStopCamera}
//                           style={{ width: "150px" }}
//                         >
//                           <FontAwesomeIcon icon={faStopCircle} /> Stop Camera
//                         </button>
//                       </div>
//                     </>
//                   ) : (
//                     <div className="text-danger text-center">
//                       <p>Camera is turned off.</p>
//                       <button
//                         className="btn btn-success"
//                         onClick={() => setIsCameraOn(true)}
//                       >
//                         Turn On Camera
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-6">
//               <div className="card p-4">
//                 <h2 className="card-title mb-4">Captured Photos</h2>
//                 <div className="row row-cols-1 row-cols-md-3 g-4">
//                   {capturedImages.map((image, index) => (
//                     <div key={index} className="col mb-4">
//                       <div className="card h-100">
//                         <img
//                           src={image}
//                           alt={`Captured ${index + 1}`}
//                           className="card-img-top rounded"
//                           style={{ maxHeight: "300px", objectFit: "cover" }}
//                         />
//                         <div className="card-body">
//                           <h5 className="card-title">Photo {index + 1}</h5>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>

//                 {capturedCount === 3 && !isCameraOn && (
//                   <div className="text-success mt-4 text-center">
//                     <FontAwesomeIcon icon={faCheckCircle} /> Verify your photo!
//                     <button
//                       type="button"
//                       className="btn btn-success mt-2"
//                       onClick={handleSavePhotos}
//                     >
//                       Save Photos
//                     </button>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// import React, { useState, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCamera,
//   faCheckCircle,
//   faStopCircle,
// } from "@fortawesome/free-solid-svg-icons";
// import Webcam from "react-webcam";
// import AaasStudentNav from "../Pages/AaasStudentNav";
// import SDSdebar from "../Pages/SDSdebar";
// import Swal from "sweetalert2";

// export default function FaceVerification() {
//   const [capturedImages, setCapturedImages] = useState([]);
//   const [capturedCount, setCapturedCount] = useState(0);
//   const webcamRef = useRef(null);
//   const [isCameraOn, setIsCameraOn] = useState(true);
//   const [isLoading, setIsLoading] = useState(false);

//   const handleCapture = () => {
//     const imageSrc = webcamRef.current.getScreenshot();
//     setCapturedImages((prevImages) => [...prevImages, imageSrc]);
//     setCapturedCount((prevCount) => prevCount + 1);

//     if (capturedCount === 2) {
//       handleStopCamera();
//     }
//   };

//   const handleStopCamera = () => {
//     setIsCameraOn(false);
//   };

//   const handleSavePhotos = async () => {
//     setIsLoading(true);

//     const user_id = localStorage.getItem("user_id");
//     const exam_id = localStorage.getItem("exam_id");
//     if (!user_id || !exam_id) {
//       console.error("User ID or Exam ID not found in local storage");
//       setIsLoading(false);
//       return;
//     }

//     const formData = new FormData();
//     formData.append("user_id", user_id);
//     formData.append("exam_id", exam_id);
//     capturedImages.forEach((image, index) => {
//       formData.append(`photo${index + 1}_base64`, image);
//     });

//     try {
//       const response = await fetch(
//         "https://newaaas.kolhapurdakshin.com/save_photos/",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );
//       const data = await response.json();
//       console.log(data);

//       if (data.verified) {
//         Swal.fire({
//           icon: "success",
//           title: "Verification Successful!",
//           text: "Your photos have been saved successfully.",
//           confirmButtonText: "Ready to Start Exam! ->",
//         }).then(() => {
//           window.location.href =
//             "/scheduleExam/ongongExam/sexamDetails/faceVerfcaton/startExam";
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Verification Failed!",
//           text: "Your photos could not be verified. Please Refresh the Page and try again.",
//           confirmButtonText: "Refresh the Page and try again",
//         });
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div style={{ marginTop: "60px" }}>
//       <AaasStudentNav />
//       <div className="wrapper">
//         <SDSdebar />

//         <div
//           className="main p-3"
//           style={{
//             flex: "1",
//             overflow: "auto",
//             background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
//           }}
//         >
//           <div className="row">
//             <div className="col-md-6">
//               <div className="card p-4">
//                 <h2 className="card-title mb-4">Face Verification</h2>
//                 <p className="text-danger card-text">
//                   Please capture <strong>Three photos</strong> of your face to
//                   proceed with the verification process.
//                 </p>

//                 <div className="mb-3">
//                   {isCameraOn ? (
//                     <>
//                       <div
//                         className="text-start mb-3"
//                         style={{ position: "relative" }}
//                       >
//                         <div className="text-start">
//                           <div>
//                             <Webcam
//                               audio={false}
//                               ref={webcamRef}
//                               screenshotFormat="image/jpeg"
//                               style={{
//                                 width: "100%",
//                                 maxWidth: "400px",
//                                 height: "100%",
//                                 border: "1px solid black",
//                                 position: "relative",
//                                 zIndex: 1,
//                               }}
//                             />
//                           </div>
//                           <div
//                             className="text-center"
//                             style={{
//                               top: 0,
//                               left: 0,
//                               width: "100%",
//                               maxWidth: "400px",
//                               height: "100%",
//                               position: "absolute",
//                               backgroundImage:
//                                 "url('https://cdn.dribbble.com/users/3496409/screenshots/7749099/scanner.gif')",
//                               backgroundSize: "cover",
//                               opacity: 0.3,
//                               zIndex: 2,
//                             }}
//                           ></div>
//                         </div>
//                       </div>
//                       <div className="text-center mb-3">
//                         <button
//                           className="btn btn-primary"
//                           onClick={handleCapture}
//                           disabled={capturedCount >= 3}
//                         >
//                           <FontAwesomeIcon icon={faCamera} /> Capture Photo
//                         </button>
//                       </div>
//                       <div className="text-center">
//                         <button
//                           className="btn btn-danger"
//                           onClick={handleStopCamera}
//                           style={{ width: "150px" }}
//                         >
//                           <FontAwesomeIcon icon={faStopCircle} /> Stop Camera
//                         </button>
//                       </div>
//                     </>
//                   ) : (
//                     <div className="text-danger text-center">
//                       <p>Camera is turned off.</p>
//                       <button
//                         className="btn btn-success"
//                         onClick={() => setIsCameraOn(true)}
//                       >
//                         Turn On Camera
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-6">
//               <div className="card p-4">
//                 <h2 className="card-title mb-4">Captured Photos</h2>
//                 <div className="row row-cols-1 row-cols-md-3 g-4">
//                   {capturedImages.map((image, index) => (
//                     <div key={index} className="col mb-4">
//                       <div className="card h-100">
//                         <img
//                           src={image}
//                           alt={`Captured ${index + 1}`}
//                           className="card-img-top rounded"
//                           style={{ maxHeight: "300px", objectFit: "cover" }}
//                         />
//                         <div className="card-body">
//                           <h5 className="card-title">Photo {index + 1}</h5>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>

//                 {capturedCount === 3 && !isCameraOn && (
//                   <div className="text-success mt-4 text-center">
//                     <FontAwesomeIcon icon={faCheckCircle} /> Verify your photo!
//                     <button
//                       type="button"
//                       className="btn btn-success mt-2"
//                       onClick={handleSavePhotos}
//                       disabled={isLoading}
//                     >
//                       {isLoading ? (
//                         <span
//                           className="spinner-border spinner-border-sm"
//                           role="status"
//                           aria-hidden="true"
//                         ></span>
//                       ) : (
//                         "Save Photos"
//                       )}
//                     </button>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCheckCircle,
  faStopCircle,
} from "@fortawesome/free-solid-svg-icons";
import Webcam from "react-webcam";
import AaasStudentNav from "../Pages/AaasStudentNav";
import SDSdebar from "../Pages/SDSdebar";
import Swal from "sweetalert2";

export default function FaceVerification() {
  const [capturedImages, setCapturedImages] = useState([]);
  const [capturedCount, setCapturedCount] = useState(0);
  const webcamRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImages((prevImages) => [...prevImages, imageSrc]);
    setCapturedCount((prevCount) => prevCount + 1);

    if (capturedCount === 2) {
      handleStopCamera();
    }
  };

  const handleStopCamera = () => {
    setIsCameraOn(false);
  };

  const handleSavePhotos = async () => {
    setIsLoading(true);

    const user_id = localStorage.getItem("user_id");
    const exam_id = localStorage.getItem("exam_id");
    if (!user_id || !exam_id) {
      console.error("User ID or Exam ID not found in local storage");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("exam_id", exam_id);
    capturedImages.forEach((image, index) => {
      formData.append(`photo${index + 1}_base64`, image);
    });

    try {
      const response = await fetch(
        "https://newaaas.kolhapurdakshin.com/save_photos/",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      console.log(data);

      if (data.verified) {
        Swal.fire({
          icon: "success",
          title: "Verification Successful!",
          text: "Your photos have been saved successfully.",
          confirmButtonText: "Ready to Start Exam! ->",
        }).then(() => {
          window.location.href =
            "/scheduleExam/ongongExam/sexamDetails/faceVerfcaton/startExam";
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Verification Failed!",
          text: "Your photos could not be verified. Please Refresh the Page and try again.",
          confirmButtonText: "Refresh the Page and try again",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />

        <div
          className="main p-3"
          style={{
            flex: "1",
            overflow: "auto",
            background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
          }}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="card p-4">
                <h2 className="card-title mb-4">Face Verification</h2>
                <p className="text-danger card-text">
                  Please capture <strong>Three photos</strong> of your face to
                  proceed with the verification process.
                </p>

                <div className="mb-3">
                  {isCameraOn ? (
                    <>
                      <div
                        className="text-start mb-3"
                        style={{ position: "relative" }}
                      >
                        <div className="text-start">
                          <div>
                            <Webcam
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              style={{
                                width: "100%",
                                maxWidth: "400px",
                                height: "100%",
                                border: "1px solid black",
                                position: "relative",
                                zIndex: 1,
                              }}
                            />
                          </div>
                          <div
                            className="text-center"
                            style={{
                              top: 0,
                              left: 0,
                              width: "100%",
                              maxWidth: "400px",
                              height: "100%",
                              position: "absolute",
                              backgroundImage:
                                "url('https://cdn.dribbble.com/users/3496409/screenshots/7749099/scanner.gif')",
                              backgroundSize: "cover",
                              opacity: 0.3,
                              zIndex: 2,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="text-center mb-3">
                        <button
                          className="btn btn-primary"
                          onClick={handleCapture}
                          disabled={capturedCount >= 3}
                        >
                          <FontAwesomeIcon icon={faCamera} /> Capture Photo
                        </button>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-danger"
                          onClick={handleStopCamera}
                          style={{ width: "150px" }}
                        >
                          <FontAwesomeIcon icon={faStopCircle} /> Stop Camera
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="text-danger text-center">
                      <p>
                        Camera is turned off. Refresh the page for Recapture .if
                        photo are not proper
                      </p>
                      <p>if photo are not proper</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card p-4">
                <h2 className="card-title mb-4">Captured Photos</h2>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                  {capturedImages.map((image, index) => (
                    <div key={index} className="col mb-4">
                      <div className="card h-100">
                        <img
                          src={image}
                          alt={`Captured ${index + 1}`}
                          className="card-img-top rounded"
                          style={{ maxHeight: "300px", objectFit: "cover" }}
                        />
                        <div className="card-body">
                          <h5 className="card-title">Photo {index + 1}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {capturedCount === 3 && !isCameraOn && (
                  <div className="text-success mt-4 text-center">
                    <FontAwesomeIcon icon={faCheckCircle} /> Verify your photo!
                    <button
                      type="button"
                      className="btn btn-success mt-2"
                      onClick={handleSavePhotos}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Save Photos"
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
