import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faListAlt,
  faParagraph,
  faCode,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import AaasNav from "../Pages/AaasNav";
import Sidebar from "../Pages/Sidebar";
import SingleMcq from "../Pages/SingleTypeMcq";
import MultipleMcq from "../Pages/MultipleTypeMcq";
import Truefalse from "../Pages/TrueFalse";
import Codinground from "../Pages/CodeQuz";

export default function AddExam() {
  const [categories, setCategories] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [types, setTypes] = useState([]);
  const [category, setCategory] = useState("");
  const [sub_category, setSubCategory] = useState("");
  const [type, setType] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://newaaas.kolhapurdakshin.com/add_quiz/?category_id=${category}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const responseData = await response.json();
        setCategories(responseData.categories);
        setSubCategoryOptions(responseData.subcategories);
        setTypes(responseData.types);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [category]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      category,
      sub_category,
      type,
    };
    console.log("Form submitted with payload:", payload);
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasNav />
      <div className="wrapper">
        <Sidebar />
        <div
          className="main p-3 text-center"
          style={{ flex: "1", overflow: "auto" }}
        >
          <h1>Aaas Question bank setup</h1>

          <button
            onClick={() => setShowForm(true)}
            style={{
              backgroundColor: "#0e2238",
              color: "white",
              border: "none",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
              borderRadius: "8px",
            }}
          >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: "8px" }} />
            Add New Quiz
          </button>

          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}

          {showForm && !loading && !error && (
            <form
              onSubmit={handleSubmit}
              style={{ marginTop: "20px", gap: "10px" }}
            >
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="border-gray-300 border rounded-md px-4 py-2 focus:outline-none"
                style={{ marginRight: "15px" }}
              >
                <option value="">Select Category</option>
                {categories.map((category, index) => (
                  <option key={index} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>

              <select
                value={sub_category}
                onChange={(e) => setSubCategory(e.target.value)}
                className="border-gray-300 border rounded-md px-4 py-2 focus:outline-none"
                style={{ marginRight: "15px" }}
              >
                <option value="">Select Subcategory</option>
                {subCategoryOptions.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>

              <select
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="border-gray-300 border rounded-md px-4 py-2 focus:outline-none"
              >
                <option value="">Select Type</option>
                {types.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </form>
          )}

          {/* Render corresponding component based on selected type */}
          {type === "single choice MCQ" && (
            <SingleMcq
              category={category}
              sub_category={sub_category}
              type={type}
            />
          )}
          {type === "Mutiple choice MCQ" && (
            <MultipleMcq
              category={category}
              sub_category={sub_category}
              type={type}
            />
          )}
          {type === "true false" && (
            <Truefalse
              category={category}
              sub_category={sub_category}
              type={type}
            />
          )}
          {type === "codinground" && (
            <Codinground
              category={category}
              sub_category={sub_category}
              type={type}
            />
          )}
        </div>
      </div>
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faPlus,
//   faListAlt,
//   faParagraph,
//   faCode,
//   faCheckSquare,
// } from "@fortawesome/free-solid-svg-icons";
// import AaasNav from "../Pages/AaasNav";
// import Sidebar from "../Pages/Sidebar";
// import SingleMcq from "../Pages/SingleTypeMcq";
// import MultipleMcq from "../Pages/MultipleTypeMcq";
// import Truefalse from "../Pages/TrueFalse";
// import Codinground from "../Pages/CodeQuz";

// export default function AddExam() {
//   const [categories, setCategories] = useState([]);
//   const [sub_categoryOptions, setsub_categoryOptions] = useState([]);
//   const [types, setTypes] = useState([]);
//   const [category, setCategory] = useState("");
//   const [sub_category, setsub_category] = useState("");
//   const [type, setType] = useState("");
//   const [showForm, setShowForm] = useState(false);

//   // Function to fetch data from API
//   const fetchData = async () => {
//     try {
//       const response = await fetch("https://newaaas.kolhapurdakshin.com/add_quiz/");
//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }
//       const responseData = await response.json();
//       setCategories(responseData.categories);
//       setsub_categoryOptions(responseData.subcategories);
//       setTypes(responseData.types);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   // Function to handle form submission
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const payload = {
//       category,
//       sub_category,
//       type,
//     };
//     // Here you can do something with the payload, such as sending it to an API
//     console.log("Form submitted with payload:", payload);
//   };

//   return (
//     <div>
//       <AaasNav />
//       <div className="wrapper">
//         <Sidebar />
//         <div
//           className="main p-3 text-center"
//           style={{
//             flex: "1",
//             overflow: "auto",
//           }}
//         >
//           <h1>Aaas Question bank setup</h1>

//           <button
//             onClick={() => {
//               setShowForm(true);
//             }}
//             style={{
//               backgroundColor: "#0e2238",
//               color: "white",
//               border: "none",
//               padding: "15px 32px",
//               textAlign: "center",
//               textDecoration: "none",
//               display: "inline-block",
//               fontSize: "16px",
//               margin: "4px 2px",
//               cursor: "pointer",
//               borderRadius: "8px",
//             }}
//           >
//             <FontAwesomeIcon icon={faPlus} style={{ marginRight: "8px" }} />
//             Add New Quiz
//           </button>
//           {showForm && (
//             <form onSubmit={handleSubmit} style={{ marginTop: "20px", gap: "10px" }}>
//               <select
//                 value={category}
//                 onChange={(e) => setCategory(e.target.value)}
//                 className="border-gray-300 border rounded-md px-4 py-2 focus:outline-none"
//                 style={{ marginRight: "15px" }}
//               >
//                 <option value="">Select Category</option>
//                 {categories.map((category, index) => (
//                   <option key={index} value={category}>
//                     {category}
//                   </option>
//                 ))}
//               </select>

//               <select
//                 value={sub_category}
//                 onChange={(e) => setsub_category(e.target.value)}
//                 className="border-gray-300 border rounded-md px-4 py-2 focus:outline-none"
//                 style={{ marginRight: "15px" }}
//               >
//                 <option value="">Select sub_category</option>
//                 {sub_categoryOptions.map((option, index) => (
//                   <option key={index} value={option}>
//                     {option}
//                   </option>
//                 ))}
//               </select>

//               <select
//                 value={type}
//                 onChange={(e) => setType(e.target.value)}
//                 className="border-gray-300 border rounded-md px-4 py-2 focus:outline-none"
//               >
//                 <option value="">Select Type</option>
//                 {types.map((option, index) => (
//                   <option key={index} value={option}>
//                     {option}
//                   </option>
//                 ))}
//               </select>
//             </form>
//           )}

//           {/* Render corresponding component based on selected type */}
//           {type === "single choice MCQ" && <SingleMcq category={category} sub_category={sub_category} type={type} />}
//           {type === "Mutiple choice MCQ" && <MultipleMcq category={category} sub_category={sub_category} type={type} />}
//           {type === "true false" && <Truefalse category={category} sub_category={sub_category} type={type} />}
//           {type === "codinground" && <Codinground category={category} sub_category={sub_category} type={type} />}
//         </div>
//       </div>
//     </div>
//   );
// }
