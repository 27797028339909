import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import logo from "./Aaaslogo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { Navbar } from "react-bootstrap";

export default function CustomNavbar({ handleToggleSidebar }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [full_name, setFullName] = React.useState("");
  const [role, setRole] = React.useState("");
  const [userActive, setUserActive] = React.useState(false);
  const [photo, setPhoto] = React.useState(""); // State to store user photo

  React.useEffect(() => {
    // Retrieve user information from local storage
    const storedFullName = localStorage.getItem("full_name");
    const storedRole = localStorage.getItem("role");
    const storedPhoto = localStorage.getItem("photo"); // Retrieve photo from local storage
    setFullName(storedFullName);
    setRole(storedRole);
    setPhoto(storedPhoto); // Set photo state
    setUserActive(true); // Assuming the user is active by default
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLinkClick = (link) => {
    console.log(`Clicked on ${link}`);
    if (link === "Logout") {
      logout();
    } else {
      handleCloseNavMenu();
    }
  };

  const logout = () => {
    // Clear local storage data
    localStorage.removeItem("full_name");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("photo");
    // Redirect to login page
    window.location.href = "/login"; // Assuming your login page route is "/login"
  };

  const renderRole = () => {
    if (role === "2") {
      return "Instructor";
    } else if (role === "3") {
      return "Student";
    } else {
      return role;
    }
  };

  const settings = [];
  //sx={{ bgcolor: "#062248" }}
  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <AppBar sx={{ bgcolor: "" }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ height: "80px", width: "auto" }}
            />
          </Typography>
          {/* <button
          onClick={() => {
            handleToggleSidebar();
          }}
          className="btn MenuToggleBar"
        >
          <MenuIcon fontSize="large" />
        </button> */}
          <Box sx={{ flexGrow: 1 }}>
            {/* <Button
            onClick={handleOpenNavMenu}
            sx={{ my: 2, display: { xs: "none", md: "block" } }}
          >
            Menu
          </Button> */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MenuItem onClick={() => handleLinkClick("Home")}>Home</MenuItem>
            </Menu>
          </Box>
          <Button
            onClick={() => handleLinkClick("Logout")}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            Logout
          </Button>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="User"
                  src={photo}
                  style={{
                    border: "2px solid white", // Add a white border to the dot
                  }} // Use the photo from local storage
                />
                {userActive && (
                  <div
                    style={{
                      backgroundColor: "green",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      position: "absolute",
                      bottom: 27,
                      right: 0,
                      border: "2px solid white", // Add a white border to the dot
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>

            <Typography sx={{ mr: 0, color: "white", marginLeft: 1 }}>
              {full_name && `${full_name} - `}
              {renderRole()}
            </Typography>

            {/* <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={() => handleLinkClick("Logout")}>
                Logout
              </MenuItem>
            </Menu> */}
          </Box>
        </Toolbar>
      </AppBar>
    </Navbar>
  );
}
