import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faLock,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import bregster from "../Pages/bregster.png";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleResetPassword = () => {
    // Check if passwords match
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Passwords do not match!",
        text: "Please make sure your new password and confirm password match.",
      });
      return;
    }

    const userId = localStorage.getItem("user_id");

    const payload = {
      user_id: userId,
      new_password: newPassword,
    };

    axios
      .post("https://newaaas.kolhapurdakshin.com/reset_password/", payload)
      .then((response) => {
        if (response.status === 200) {
          console.log("Password reset successful");
          Swal.fire({
            icon: "success",
            title: "Password Reset Successful!",
            text: "Your password has been successfully reset.",
          }).then(() => {
            // Navigate to login page
            navigate("/login");
          });
        } else {
          console.error("Password reset failed");
          Swal.fire({
            icon: "error",
            title: "Password Reset Failed!",
            text: "Something went wrong. Please try again later.",
          });
        }
      })
      .catch((error) => {
        console.error("Error resetting password:", error);
        Swal.fire({
          icon: "error",
          title: "Error Resetting Password!",
          text: "Something went wrong. Please try again later.",
        });
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7 d-none d-md-block">
          <img
            src={bregster}
            alt="Your Image Alt Text"
            className="img-fluid"
            style={{ height: "100%" }}
          />
        </div>

        <div className="col-md-5">
          <div className="main p-4 rounded shadow bg-light">
            <h2 className="text-center mb-4">Reset Password</h2>
            <form style={{ maxWidth: "400px", margin: "0 auto" }}>
              <div className="mb-3">
                <label htmlFor="newPassword" className="form-label">
                  New Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                {errorMessage && (
                  <div className="alert alert-danger">{errorMessage}</div>
                )}
                <button
                  type="button"
                  className="btn btn-dark btn-block"
                  onClick={handleResetPassword}
                >
                  <FontAwesomeIcon icon={faLock} className="mr-2" />
                  &nbsp; Reset Password
                </button>
              </div>
              <div className="text-center">
                <p className="mb-0">
                  <a href="/login" className="text-dark">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
                    {""}
                    Back to Login
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
