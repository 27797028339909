import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";
import { Card, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
  faUserGraduate,
  faUsers,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Subcategory = () => {
  const [showModal, setShowModal] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    // Fetch categories from API
    fetch("https://newaaas.kolhapurdakshin.com/fetch_categories/")
      .then((response) => response.json())
      .then((data) => {
        setCategories(data.categories); // Update to set only the categories array
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAddSubcategory = () => {
    // Make API call to add subcategory
    fetch("https://newaaas.kolhapurdakshin.com/add_subcategory/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subcategoryName, selectedCategory }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Subcategory added successfully:", data);
        // Show success message using SweetAlert
        Swal.fire({
          icon: "success",
          title: "Subcategory Added",
          text: "Subcategory has been added successfully!",
        });
        // Close the modal
        closeModal();
      })
      .catch((error) => {
        console.error("Error adding subcategory:", error);
        // Optionally, you can handle errors and show an error message to the user
      });
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div className="main p-3">
          <div className="text-center">
            <Card className="dashboard-card">
              <Card.Body>
                <Button
                  variant="primary"
                  className="mb-3"
                  onClick={openModal}
                  style={{ width: "200px", marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Subcategory
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Subcategory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="category">Category:</label>
              <select
                className="form-control"
                id="category"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.category}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="subcategoryName">Subcategory Name:</label>
              <input
                type="text"
                className="form-control"
                id="subcategoryName"
                name="subcategoryName"
                placeholder="Enter Subcategory name"
                value={subcategoryName}
                onChange={(e) => setSubcategoryName(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} /> Close
          </Button>
          <Button variant="primary" onClick={handleAddSubcategory}>
            <FontAwesomeIcon icon={faPlus} /> Add Subcategory
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Subcategory;
