// import React, { useState, useRef } from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Grid from "@mui/material/Grid";
// import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
// import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
// import Typography from "@mui/material/Typography";
// import { Link } from "react-router-dom";
// import videoSrc from "./aaaaaas.mp4"; // Update the path

// export default function Open() {
//   const [isPlaying, setIsPlaying] = useState(false);
//   const videoRef = useRef(null);

//   const togglePlay = () => {
//     if (videoRef.current.paused) {
//       videoRef.current.play();
//       setIsPlaying(true);
//     } else {
//       videoRef.current.pause();
//       setIsPlaying(false);
//     }
//   };

//   return (
//     <Grid container spacing={0}>
//       <Grid
//         item
//         xs={8}
//         style={{
//           backgroundColor: "#001861",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           position: "relative",
//           overflow: "hidden",
//           boxShadow: "0px 10px 20px rgba(255, 255, 255, 0.3)", // changed shadow to white
//         }}
//       >
//         <video
//           ref={videoRef}
//           loop
//           style={{
//             maxWidth: "90%", // reduced width
//             maxHeight: "90vh", // reduced height
//             objectFit: "cover", // cover video to fill container
//             transition: "transform 0.5s ease",
//             transform: isPlaying ? "scale(1.1)" : "scale(1)",
//             boxShadow: "0px 5px 10px rgba(255, 255, 255, 0.3)", // white shadow
//           }}
//           onClick={togglePlay}
//         >
//           <source src={videoSrc} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//         <div
//           style={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             color: "white",
//             display: isPlaying ? "none" : "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             fontSize: "3em",
//             cursor: "pointer",
//             transition: "opacity 0.5s ease",
//             opacity: isPlaying ? 0 : 1,
//           }}
//         >
//           <PlayCircleFilledIcon style={{ fontSize: "inherit" }} />
//         </div>
//         {isPlaying && (
//           <div
//             style={{
//               position: "absolute",
//               bottom: 0,
//               left: 0,
//               width: "100%",
//               height: "2px",
//               backgroundColor: "#FF007E",
//             }}
//           >
//             <div
//               style={{
//                 width: "0%",
//                 height: "100%",
//                 backgroundColor: "white",
//                 transition: "width 0.1s linear",
//               }}
//             />
//           </div>
//         )}
//       </Grid>
//       <Grid
//         item
//         xs={4}
//         style={{
//           backgroundColor: "#FF007E",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100vh",
//         }}
//       >
//         <Card
//           elevation={10}
//           style={{
//             padding: "20px",
//             borderRadius: "20px",
//             textAlign: "center",
//             margin: "20px",
//             backgroundColor: "rgba(255, 255, 255, 0.8)",
//           }}
//         >
//           <CardContent>
//             <Typography variant="h5" style={{ color: "#FF007E" }}>
//               Assessment as a Service
//             </Typography>
//             <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
//               "Fuel Your Future: Assessment as a Service - Ignite Your Learning
//               Journey with Power, Passion, and Confidence!"
//             </Typography>
//             <Link to="/login">
//               <button
//                 style={{
//                   marginTop: "30px",
//                   padding: "10px 30px",
//                   fontSize: "1.2em",
//                   backgroundColor: "#000",
//                   color: "#FFF",
//                   border: "none",
//                   borderRadius: "20px",
//                   cursor: "pointer",
//                   transition: "transform 0.3s ease",
//                   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
//                 }}
//                 onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
//                 onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
//               >
//                 Get Started →
//               </button>
//             </Link>
//           </CardContent>
//         </Card>
//       </Grid>
//     </Grid>
//   );
// }
import React, { useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import videoSrc from "./aaaaaas.mp4"; // Update the path

export default function Open() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        md={8}
        style={{
          backgroundColor: "#001861",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
          boxShadow: "0px 10px 20px rgba(255, 255, 255, 0.3)", // changed shadow to white
        }}
      >
        <video
          ref={videoRef}
          loop
          style={{
            width: "90%",
            height: "90%",
            objectFit: "cover", // cover video to fill container
            transition: "transform 0.5s ease",
            transform: isPlaying ? "scale(1.1)" : "scale(1)",
            boxShadow: "0px 5px 10px rgba(255, 255, 255, 0.3)", // white shadow
          }}
          onClick={togglePlay}
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            display: isPlaying ? "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "3em",
            cursor: "pointer",
            transition: "opacity 0.5s ease",
            opacity: isPlaying ? 0 : 1,
          }}
        >
          <PlayCircleFilledIcon style={{ fontSize: "inherit" }} />
        </div>
        {isPlaying && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "2px",
              backgroundColor: "#FF007E",
            }}
          >
            <div
              style={{
                width: "0%",
                height: "100%",
                backgroundColor: "white",
                transition: "width 0.1s linear",
              }}
            />
          </div>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        style={{
          backgroundColor: "#FF007E",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Card
          elevation={10}
          style={{
            padding: "20px",
            borderRadius: "20px",
            textAlign: "center",
            margin: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <CardContent>
            <Typography variant="h5" style={{ color: "#FF007E" }}>
              Assessment as a Service
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
              "Fuel Your Future: Assessment as a Service - Ignite Your Learning
              Journey with Power, Passion, and Confidence!"
            </Typography>
            <Link to="/login">
              <button
                style={{
                  marginTop: "30px",
                  padding: "10px 30px",
                  fontSize: "1.2em",
                  backgroundColor: "#000",
                  color: "#FFF",
                  border: "none",
                  borderRadius: "20px",
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                }}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              >
                Get Started →
              </button>
            </Link>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
