import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";
import { Card, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Swal from "sweetalert2"; // Import SweetAlert

const Addrole = () => {
  const [showModal, setShowModal] = useState(false);
  const [role_name, setRoleName] = useState("");
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchRoles(); // Fetch roles when component mounts
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleRoleNameChange = (event) => {
    setRoleName(event.target.value);
  };

  const addRole = () => {
    axios
      .post("https://newaaas.kolhapurdakshin.com/add_roles/", { role_name })
      .then((response) => {
        console.log(response.data); // Log success message
        closeModal();
        fetchRoles(); // Fetch roles again after adding a new one
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Role added successfully",
        });
      })
      .catch((error) => {
        console.error("Error adding role:", error);
      });
  };

  const fetchRoles = () => {
    axios
      .get("https://newaaas.kolhapurdakshin.com/fetch_role/")
      .then((response) => {
        setRoles(response.data.roles); // Set roles array from response data
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div className="main p-3">
          <div className="text-center">
            <Card className="dashboard-card">
              <Card.Body>
                <Button
                  variant="primary"
                  className="mb-3"
                  onClick={openModal}
                  style={{ width: "200px", marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Roles
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="roleName">Role Name:</label>
              <input
                type="text"
                className="form-control"
                id="roleName"
                name="roleName"
                value={role_name}
                onChange={handleRoleNameChange}
                placeholder="Enter role name"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} /> Close
          </Button>
          <Button variant="primary" onClick={addRole}>
            <FontAwesomeIcon icon={faPlus} /> Add Role
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Display fetched roles */}
      <div>
        <h2>Roles</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Role ID</th>
              <th>Role Name</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr key={role.role_id}>
                <td>{role.role_id}</td>
                <td>{role.role_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Addrole;
