import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
export default function ASidebar() {
  const navigate = useNavigate();

  const navigateToAdmnDashboards = () => {
    navigate("/AdmnDashboard");
  };
  const navigateToaAddExam = () => {
    navigate("/AaddExams");
  };
  const navigateToaAllExams = () => {
    navigate("/AallExams");
  };
  const navigateToaAaddUser = () => {
    navigate("/AaddUser");
  };
  const navigateToaUsersRoles = () => {
    navigate("/AUsersRoles");
  };
  const navigateToaQuestionsCategory = () => {
    navigate("/AquestonCategory");
  };
  const navigateToaQuestionsSubcategory = () => {
    navigate("/AquestonSubcategory");
  };
  const navigateToaAddQuz = () => {
    navigate("/AaddQuz");
  };
  const navigateToaQuestonLst = () => {
    navigate("/AallQuestonlst");
  };
  const navigateToAdmnProfle = () => {
    navigate("/AdmnProfle");
  };
  const navigateToaAccessControl = () => {
    navigate("/AccessControl");
  };
  //navigateToAdmnProfle navigateToaAccessControl
  return (
    <div className="">
      <aside
        id="sidebar"
        className="collapsed expand "
        style={{ height: "100%" }}
      >
        <div className="d-flex justify-content-between">
          <div className="sidebar-logo">{/* <a href="#">Aaas</a> */}</div>
          <button
            className="toggle-btn"
            type="button"
            onClick={() =>
              document.querySelector("#sidebar").classList.toggle("expand")
            }
            style={{ background: "transparent", color: "white" }}
          >
            <i className="lni lni-menu"></i>
          </button>
        </div>

        <ul className="sidebar-nav">
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link"
              onClick={navigateToAdmnDashboards}
            >
              <i className="lni lni-home"></i>
              <span> Dashboard</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a href="#" className="sidebar-link" onClick={navigateToAdmnProfle}>
              <i className="lni lni-user"></i>

              <span> Profile</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#auth"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-protection"></i>
              <span>Exams</span>
            </a>
            <ul
              id="auth"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToaAddExam}
                >
                  Add Exams
                </a>
              </li>
              <li className="sidebar-item text-start ps-4 ">
                <a
                  href="#"
                  className="sidebar-link"
                  onClick={navigateToaAllExams}
                >
                  All Exams
                </a>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#auths"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-question-circle"></i>
              <span>Question Bank</span>
            </a>
            <ul
              id="auths"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link  "
                  onClick={navigateToaAddQuz}
                >
                  Add Quiz{" "}
                </a>
              </li>
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToaQuestonLst}
                >
                  Questions List
                </a>
              </li>
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToaQuestionsCategory}
                >
                  Questions Category
                </a>
              </li>
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToaQuestionsSubcategory}
                >
                  Questions Subcategory
                </a>
              </li>
            </ul>
          </li>

          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#authss"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-question-circle"></i>
              <span>Users </span>
            </a>
            <ul
              id="authss"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link  "
                  onClick={navigateToaAaddUser}
                >
                  Add User{" "}
                </a>
              </li>

              {/* <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToaUsersRoles}
                >
                  Users Roles
                </a>
              </li> */}
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#authssss"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-question-circle"></i>
              <span>Reports</span>
            </a>
            <ul
              id="authssss"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link  "
                  //onClick={navigateToaResults}
                >
                  Results{" "}
                </a>
              </li>
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link  "
                  //onClick={navigateToaResults}
                >
                  Exams Report{" "}
                </a>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#authsssss"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-question-circle"></i>
              <span>Settings</span>
            </a>
            <ul
              id="authsssss"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link  "
                  onClick={navigateToaAccessControl}
                >
                  Access Control{" "}
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div className="sidebar-footer">
          <a href="#" className="sidebar-link">
            <i className="lni lni-exit"></i>
            <span>Sign-out</span>
          </a>
        </div>
      </aside>
    </div>
  );
}
