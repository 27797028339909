import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";
import { useSpring, animated } from "react-spring";
import { FaBell, FaEnvelope, FaArrowRight } from "react-icons/fa";

export default function Notification() {
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <animated.div
          className="main p-3"
          style={{ ...styles.container, ...fadeIn }}
        >
          <div className="text-center mb-4">
            <h2 style={styles.heading}>Notifications</h2>
          </div>
          <div style={styles.notificationCard}>
            <div style={styles.notificationIcon}>
              <FaBell />
            </div>
            <div style={styles.notificationContent}>
              <p style={styles.notificationText}>
                <strong>Important Notice:</strong> The upcoming exam has been
                rescheduled to February 15, 2024.
              </p>
              <span style={styles.notificationTime}>2 hours ago</span>
            </div>
            <div style={styles.notificationAction}>
              <FaArrowRight />
            </div>
          </div>
          <div style={styles.notificationCard}>
            <div style={styles.notificationIcon}>
              <FaEnvelope />
            </div>
            <div style={styles.notificationContent}>
              <p style={styles.notificationText}>
                <strong>New Message:</strong> You have a new message from your
                instructor. Please check your inbox.
              </p>
              <span style={styles.notificationTime}>1 day ago</span>
            </div>
            <div style={styles.notificationAction}>
              <FaArrowRight />
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: "1",
    overflow: "auto",
    padding: "20px",
    borderRadius: "10px",
    background: "#f8f9fa",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "80%",
    margin: "0 auto",
  },
  heading: {
    color: "#007BFF",
    fontSize: "28px",
    marginBottom: "20px",
  },
  notificationCard: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    marginBottom: "16px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  notificationIcon: {
    fontSize: "24px",
    marginRight: "16px",
    color: "#007BFF",
  },
  notificationContent: {
    flex: "1",
  },
  notificationText: {
    fontSize: "16px",
    marginBottom: "8px",
    color: "#555",
  },
  notificationTime: {
    fontSize: "14px",
    color: "#888",
  },
  notificationAction: {
    fontSize: "20px",
    marginLeft: "16px",
    color: "#007BFF",
    cursor: "pointer",
  },
};
