import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../Pages/Sidebar";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import AaasNav from "../Pages/AaasNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faStop,
  faPlus,
  faTrash,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const McqQuz = () => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [selectedOptionType, setSelectedOptionType] = useState(null);
  const [submittedQuestions, setSubmittedQuestions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isListening, setIsListening] = useState(false);

  const { transcript, resetTranscript } = useSpeechRecognition();

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleOptionTypeSelect = (type) => {
    setSelectedOptionType(type);
  };

  const handleMicClick = () => {
    SpeechRecognition.startListening();
    setIsListening(true);
  };

  const handleMicStopClick = () => {
    SpeechRecognition.stopListening();
    setQuestion(transcript);
    resetTranscript();
    setIsListening(false);
  };

  const handleSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      // Simulate an API call or other asynchronous task
      setTimeout(() => {
        const submittedQuiz = { question, options, selectedOptionType };
        setSubmittedQuestions([...submittedQuestions, submittedQuiz]);
        setQuestion("");
        setOptions(["", "", "", ""]);
        setSelectedOptionType(null);
        setIsSubmitting(false);
      }, 1000);
    }
  };

  const renderSubmittedQuestions = () => (
    <div className="bg-light p-4 mt-5 rounded">
      <h3 className="text-primary text-center mb-4">Submitted Questions</h3>
      {submittedQuestions.map((submittedQuiz, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">{submittedQuiz.question}</h5>
            <ul className="list-group mb-3">
              {submittedQuiz.options.map((option, i) => (
                <li key={i} className="list-group-item">
                  {option}
                </li>
              ))}
            </ul>
            <p className="card-text">
              <small className="text-muted">
                Correct Option :
                {/* Correct Option Type: {submittedQuiz.selectedOptionType} */}
              </small>
            </p>
          </div>
        </div>
      ))}
    </div>
  );

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const url = window.URL.createObjectURL(file);
      setSelectedFile({
        file,
        url,
      });
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  const handleUploadImage = () => {
    document.getElementById("coverPhoto").click();
  };

  const handleMarkOption = (index) => {
    // Implement your logic for marking options here
    // You may want to update the state or perform any other actions
    console.log(`Marking option ${index} as correct`);
  };
  const renderForm = () => (
    <div>
      <div className="form-group mb-3">
        <label htmlFor="question">
          Question<span className="text-danger">*</span>:
        </label>
        <div
          style={{
            position: "relative",
            width: "50%",
            height: "200px",
            border: "1px solid #ccc",
            overflow: "hidden",
            cursor: "pointer",
            margin: "0 auto",
          }}
          onClick={() => document.getElementById("coverPhoto").click()}
        >
          {selectedFile ? (
            <>
              <img
                src={selectedFile.url}
                alt="Selected Course Cover"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <button
                className="btn btn-dark btn-action"
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                }}
                onClick={handleRemoveImage}
              >
                Remove
              </button>
              <button
                className="btn btn-dark btn-action"
                style={{
                  position: "absolute",
                  bottom: "5px",
                  right: "5px",
                }}
                onClick={handleUploadImage}
              >
                Upload
              </button>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#f0f0f0",
                border: "1px solid #ccc",
              }}
            ></div>
          )}
        </div>

        <input
          type="file"
          id="coverPhoto"
          name="coverPhoto"
          className="form-control visually-hidden"
          onChange={handleFileInputChange}
          accept="image/*"
        />
      </div>

      <div className="row">
        {/* Question Section */}
        <div className="col-md-9">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              id="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              style={{
                borderTopRightRadius: isListening ? "0" : "8px",
                borderBottomRightRadius: isListening ? "0" : "8px",
              }}
            />

            <button
              type="button"
              className={`btn ${isListening ? "btn-danger" : "btn-dark"}`}
              onClick={isListening ? handleMicStopClick : handleMicClick}
              style={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
              }}
            >
              <FontAwesomeIcon icon={isListening ? faStop : faMicrophone} />{" "}
              {isListening ? "" : ""}
            </button>
          </div>
        </div>
        {/* Mark Section */}
        <div className="col-md-3">
          <div className="form-group">
            <div className="input-group mb-3">
              <input
                type="number"
                className="form-control"
                placeholder="Add Mark"
                id="markOption"
                onChange={handleMarkOption}
              />
              <div className="input-group-append">
                <span className="input-group-text">/100</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div className="input-group mb-3">
            <div className="input-group-append">
              <span className="input-group-text">Correct Option</span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Add Correct Option"
              id="markOption"
              //onChange={handleMarkOption}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>
          Options<span className="text-danger">*</span>:
        </label>
        <ul className="list-group">
          {options.map((option, index) => (
            <li
              key={index}
              className="list-group-item d-flex align-items-center"
              style={{
                backgroundColor: "#ADD8E6",
                border: "1px solid #ADD8E6",
                borderRadius: "8px",
                marginBottom: "10px",
                padding: "10px",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <input
                type={selectedOptionType === "single" ? "radio" : "checkbox"}
                name="options"
                className="form-check-input mr-3"
                style={{
                  height: "20px",
                  width: "20px",
                }}
              />
              <input
                type="text"
                className="form-control mr-3"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                style={{
                  border: "2px solid #dee2e6",
                  borderRadius: "8px",
                  padding: "8px",
                  transition: "border-color 0.3s ease-in-out",
                  flex: "1",
                }}
              />

              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleRemoveOption(index)}
                style={{
                  borderRadius: "8px",
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <button
        type="button"
        className="btn btn-dark mr-2"
        onClick={handleAddOption}
      >
        <FontAwesomeIcon icon={faPlus} /> Add Option
      </button>

      <button
        type="button"
        className="btn btn-success"
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <>
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            ></span>
            Submitting...
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCheck} /> Submit
          </>
        )}
      </button>
      {isSubmitting && (
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={() => setIsSubmitting(false)}
        >
          <FontAwesomeIcon icon={faTimes} /> Cancel
        </button>
      )}
    </div>
  );

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  return (
    <div>
      <AaasNav />
      <div className="wrapper">
        <Sidebar />
        <div
          className="main p-3"
          style={{
            flex: "1",
            overflow: "auto",
          }}
        >
          <div className="mcq-quiz-container container mt-3">
            <h2 className="quiz-title text-center">Create MCQ Quiz</h2>
            <div className="option-type-buttons text-center mb-3">
              <button
                className={`btn btn-dark ${
                  selectedOptionType === "single" ? "" : "active"
                }`}
                onClick={() => handleOptionTypeSelect("single")}
                style={{ marginRight: "12px" }}
              >
                <FontAwesomeIcon icon={faPlus} /> Single Option
              </button>
              <button
                className={`btn btn-dark ${
                  selectedOptionType === "multiple" ? "" : "active"
                }`}
                onClick={() => handleOptionTypeSelect("multiple")}
              >
                <FontAwesomeIcon icon={faPlus} /> Multiple Options
              </button>
            </div>
            {selectedOptionType && (
              <div
                className="form-container"
                style={{ width: "70%", margin: "auto" }}
              >
                {renderForm()}
              </div>
            )}
            {renderSubmittedQuestions()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default McqQuz;
