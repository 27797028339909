import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import AutoQuizPage from "./Pages/AutoQuizPage";
import Login from "../src/Pages/Logn";
import ManualQuizPage from "./Pages/ManualQuizPage";
import AaasDashboard from "./Pages/AaasDashboard";
import Logn from "../src/Pages/Logn";
import QueBan from "./Pages/QueBan";
import AddExam from "./Pages/AddExam";
import McqQuz from "./Pages/McqQuz";
import LongAnswer from "./Pages/LongAnswer";
import ShortAnswer from "./Pages/ShortAnswer";
import CodeQuz from "./Pages/CodeQuz";
import TrueFalse from "./Pages/TrueFalse";
import AaasNav from "./Pages/AaasNav";
import ExamResult from "./Pages/ExamResult";
import AllExamLst from "./Pages/AllExamLst";
import AllQuesLst from "./Pages/AllQuesLst";
import QuestonBanLst from "./Pages/QuestonBanLst";
import AaasStudentDashboard from "./Pages/AaasStudentDashboard";
import SDSdebar from "./Pages/SDSdebar";

import OngongExam from "./Pages/OngongExam";
import UpcomngExam from "./Pages/UpcomngExam";
import CompletedExam from "./Pages/CompletedExam";
import SExamLst from "./Pages/SExamLst";
import SQuestonLst from "./Pages/SQuestonLst";
import SSuggestonFeedbac from "./Pages/SSuggestonFeedbac";
import Notfcaton from "./Pages/Notfcaton";
import SExamDetails from "./Pages/SExamDetails";
import FaceVerfcaton from "./Pages/FaceVerfcaton";
import StartExam from "./Pages/StartExam";
import ExamComplete from "./Pages/ExamComplete";
import AaasRegster from "./Pages/AaasRegster";
import AaasLogn from "./Pages/AaasLogn";
import ResetPassword from "./Pages/ResetPassword";
import AaasStudentNav from "./Pages/AaasStudentNav";
import ProfleStudent from "./Pages/ProfleStudent";
import SingleTypeMcq from "./Pages/SingleTypeMcq";
import MultipleTypeMcq from "./Pages/MultipleTypeMcq";
import OpenAaas from "./Pages/OpenAaas";
import AdmnDashboard from "./Pages/AdmnDashboard";
import AdmnSds from "./Pages/AdmnSds";
import AaddExams from "./Pages/AaddExams";
import AdmnNav from "./Pages/AdmnNav";
import AallExams from "./Pages/AallExams";
import AaddUser from "./Pages/AaddUser";
import AUsersRoles from "./Pages/AUsersRoles";
import AquestonCategory from "./Pages/AquestonCategory";
import AquestonSubcategory from "./Pages/AquestonSubcategory";
import AaddQuz from "./Pages/AaddQuz";
import AallQuestonlst from "./Pages/AallQuestonlst";
import AdmnProfle from "./Pages/AdmnProfle";
import Admnlogn from "./Pages/Admnlogn";
import ExamRequest from "./Pages/ExamRequest";
import AAccessControl from "./Pages/AAccessControl";
import VewDetals from "./Pages/VewDetals";
import Forgotpassword from "./Pages/Forgotpassword";
import AdmnRegister from "./Pages/AdmnRegister";

//forgotpassword
function App() {
  const location = useLocation();
  const handleLogin = (response) => {
    localStorage.setItem("full_name", response.data.full_name);
    localStorage.setItem("email", response.data.email);
    localStorage.setItem("password", response.data.password);
    localStorage.setItem("role", response.data.role);
    localStorage.setItem("photo", response.data.photo);
  };
  const isLoggedIn =
    localStorage.getItem("email") &&
    localStorage.getItem("password") &&
    localStorage.getItem("role");
  if (window.location.pathname !== "/login" && !isLoggedIn) {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/forgot-password"
    ) {
      // Your logic when conditions are met but no redirection needed
    }
  }
  // Your logic when conditions are met but no redirection needed

  return (
    <div>
      <Routes>
        <Route path="/login" element={<AaasLogn handleLogin={handleLogin} />} />
        <Route path="/" element={<OpenAaas />} />

        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/admnRegister" element={<AdmnRegister />} />

        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/regster" element={<AaasRegster />} />
        <Route path="/Admnlogn" element={<Admnlogn />} />
        {isLoggedIn && (
          <>
            <Route path="/AdmnSds" element={<AdmnSds />} />
            <Route path="/AdmnNav" element={<AdmnNav />} />
            <Route path="/logn/aaasDashboard" element={<AaasDashboard />} />
            <Route path="/SingleTypeMcq" element={<SingleTypeMcq />} />
            <Route path="/MultipleTypeMcq" element={<MultipleTypeMcq />} />
            <Route path="/autoQuizPage" element={<AutoQuizPage />} />
            <Route path="/manualQuizPage" element={<ManualQuizPage />} />
            <Route path="/addExam" element={<AddExam />} />
            <Route path="/questonBanLst" element={<QuestonBanLst />} />
            <Route path="/mcqQuz" element={<McqQuz />} />
            <Route path="/longAnswer" element={<LongAnswer />} />
            <Route path="/shortAnswer" element={<ShortAnswer />} />
            <Route path="/codeQuz" element={<CodeQuz />} />
            <Route path="/trueFalse" element={<TrueFalse />} />
            <Route path="/aaasNav" element={<AaasNav />} />
            <Route path="/examResult" element={<ExamResult />} />
            <Route path="/allExamLst" element={<AllExamLst />} />
            <Route path="/allQuesLst" element={<AllQuesLst />} />
            <Route path="/queban" element={<QueBan />} />
            <Route path="/vewDetals" element={<VewDetals />} />

            {/* <Route path="/contact" component={Contact} /> */}
            {/* AaasStudentDashboard  navigateTosexamDetails */}
            <Route
              path="/logn/aaasStudentDashboard"
              element={<AaasStudentDashboard />}
            />
            <Route path="/aaasStudentNav" element={<AaasStudentNav />} />
            <Route path="/profleStudent" element={<ProfleStudent />} />
            <Route path="/sDSdebar" element={<SDSdebar />} />
            <Route path="/scheduleExam/ongongExam" element={<OngongExam />} />
            <Route path="/upcomngExam" element={<UpcomngExam />} />
            <Route path="/CompletedExam" element={<CompletedExam />} />
            <Route path="/sExamLst" element={<SExamLst />} />
            <Route path="/sQuestonLst" element={<SQuestonLst />} />
            <Route path="/sSuggestonFeedbac" element={<SSuggestonFeedbac />} />
            <Route path="/notfcaton" element={<Notfcaton />} />
            <Route
              path="/scheduleExam/ongongExam/sexamDetails"
              element={<SExamDetails />}
            />
            <Route
              path="/scheduleExam/ongongExam/sexamDetails/faceVerfcaton"
              element={<FaceVerfcaton />}
            />
            <Route
              path="/scheduleExam/ongongExam/sexamDetails/faceVerfcaton/startExam"
              element={<StartExam />}
            />
            <Route path="/examComplete" element={<ExamComplete />} />
            {/* Admn */}
            <Route path="/AccessControl" element={<AAccessControl />} />

            <Route path="/AdmnDashboard" element={<AdmnDashboard />} />
            <Route path="/examComplete" element={<ExamComplete />} />
            <Route path="/AaddExams" element={<AaddExams />} />
            <Route path="/AallExams" element={<AallExams />} />
            <Route path="/AaddUser" element={<AaddUser />} />
            <Route path="/AUsersRoles" element={<AUsersRoles />} />
            <Route path="/AquestonCategory" element={<AquestonCategory />} />
            <Route
              path="/AquestonSubcategory"
              element={<AquestonSubcategory />}
            />
            <Route path="/ExamRequest" element={<ExamRequest />} />

            <Route path="/Admnlogn" element={<Admnlogn />} />
            <Route path="/AaddQuz" element={<AaddQuz />} />
            <Route path="/AallQuestonlst" element={<AallQuestonlst />} />
            <Route path="/AdmnProfle" element={<AdmnProfle />} />
            <Route path="/AdmnDashboard" element={<AdmnDashboard />} />
            <Route path="/AdmnDashboard" element={<AdmnDashboard />} />
          </>
        )}
      </Routes>
      {isLoggedIn && location.pathname === "/login" && <Navigate to="/login" />}
    </div>
  );
}

export default App;
