import React, { useState } from "react";
import "./Sidebar.css";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faUsers,
  faHistory,
  faEnvelope,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Admnprofle = () => {
  const navigate = useNavigate();

  // State for profile information
  const [profile, setProfile] = useState({
    fullName: "Anant ",
    role: "Admin", // Fixed role
    email: "Anant@example.com",
    newPassword: "",
    confirmPassword: "",
    totalStudents: 500,
    totalUsers: 1000,
    recentActivity: "...",
  });

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform action to update profile information
    console.log("Profile Updated:", profile);
  };

  // Handler for form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div className="main p-3">
          <div className="text-center">
            <Card className="profile-card">
              <Card.Body>
                <Card.Title>Admin Profile</Card.Title>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="fullName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter full name"
                        name="fullName"
                        value={profile.fullName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={profile.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="newPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        name="newPassword"
                        value={profile.newPassword}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="confirmPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm new password"
                        name="confirmPassword"
                        value={profile.confirmPassword}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admnprofle;
