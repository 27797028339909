import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Accordion, Modal, Button, Form } from "react-bootstrap";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import QuestonBanLst from "./QuestonBanLst";

const ExamList = () => {
  const [exams, setExams] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedExam, setSelectedExam] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showQuestionPopup, setShowQuestionPopup] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [updatedStartDate, setUpdatedStartDate] = useState("");
  const [updatedDuration, setUpdatedDuration] = useState("");
  const [updatedInstructor, setUpdatedInstructor] = useState("");
  const [updatedMarks, setUpdatedMarks] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  // New state variables for adding a question
  const [newQuestion, setNewQuestion] = useState("");
  const [showAddQuestionPopup, setShowAddQuestionPopup] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://newaaas.kolhapurdakshin.com/fetch_questions/",
          {}
        );
        const { exams } = response.data;
        setExams(exams);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const openPopup = (exam) => {
    setSelectedExam(exam);
    setShowPopup(true);
    setUpdatedTitle(exam.title);
    setUpdatedStartDate(exam.start_date);
    setUpdatedDuration(exam.duration);
    setUpdatedInstructor(
      exam.Full_names && Array.isArray(exam.Full_names)
        ? exam.Full_names.join(", ")
        : ""
    );
    setUpdatedMarks(exam.marks);
    setQuestions(exam.questions);
  };

  const closePopup = () => {
    setSelectedExam(null);
    setShowPopup(false);
  };

  const updateExamDetails = () => {
    closePopup();
  };

  const deleteExam = () => {
    closePopup();
  };

  const openQuestionPopup = (question) => {
    setSelectedQuestion(question);
    setShowQuestionPopup(true);
  };

  const closeQuestionPopup = () => {
    setSelectedQuestion(null);
    setShowQuestionPopup(false);
  };

  const updateQuestionDetails = () => {
    closeQuestionPopup();
  };

  const deleteQuestion = async (examId, questionId) => {
    try {
      await axios.delete(
        "https://newaaas.kolhapurdakshin.com/delete_questions/",
        {
          data: { exam_id: examId, questions_id: questionId },
        }
      );

      const updatedQuestions = questions.filter(
        (question) => question.questions_id !== questionId
      );
      setQuestions(updatedQuestions);

      console.log("Question deleted successfully.");
    } catch (error) {
      console.error("Error deleting question:", error.response.data.error);
    }
  };

  const redirectToQuestionBank = () => {
    window.location.href = "/questonBanLst";
  };

  const handleInputChange = (event, field) => {
    const value = event.target.value;
    switch (field) {
      case "title":
        setUpdatedTitle(value);
        break;
      case "start_date":
        setUpdatedStartDate(value);
        break;
      case "duration":
        setUpdatedDuration(value);
        break;
      case "instructor":
        setUpdatedInstructor(value);
        break;
      case "marks":
        setUpdatedMarks(value);
        break;
      default:
        break;
    }
  };

  const handleAddQuestionChange = (event) => {
    setNewQuestion(event.target.value);
  };

  const handleAddQuestionSubmit = () => {
    // Here you can add the logic to submit the new question to the server
    // After successful submission, you can update the state and close the popup
    // For demonstration, I'm just logging the new question here
    console.log("New question:", newQuestion);
    setNewQuestion(""); // Clearing the input field
    setShowAddQuestionPopup(false); // Closing the popup
  };

  const filteredExams = exams.filter((exam) => {
    const title = exam.title || "";
    const startDate = exam.start_date || "";
    return (
      title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      startDate.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const indexOfLastExam = currentPage * itemsPerPage;
  const indexOfFirstExam = indexOfLastExam - itemsPerPage;
  const currentExams = filteredExams.slice(indexOfFirstExam, indexOfLastExam);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "4px",
    overflow: "hidden",
    width: "300px",
    marginBottom: "20px",
  };

  const questionTextStyle = {
    fontSize: "14px",
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
          <div className="container mt-5">
            <h2>All Scheduled Exam List</h2>
            <div style={searchBarStyle}>
              <FontAwesomeIcon
                icon={faSearch}
                style={{
                  padding: "10px",
                  backgroundColor: "#4d90fe",
                  color: "white",
                  border: "none",
                  borderRadius: "0 4px 4px 0",
                  cursor: "pointer",
                }}
              />
              <input
                type="text"
                placeholder="Search by Exam Title or Date"
                className="w-50"
                style={{
                  flex: "1",
                  padding: "8px",
                  border: "none",
                  borderRadius: "4px 0 0 4px",
                  fontSize: "12px",
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="card-container">
              {filteredExams.map((exam) => (
                <div key={exam.exam_id} className="card">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="row w-100">
                          <div className="col-lg-8 col-md-12 col-sm-12 exam-info ">
                            <span
                              className="exam-name text-start "
                              style={{
                                fontWeight: "bold",
                                marginRight: "10px",
                              }}
                            >
                              {exam.title}
                            </span>
                            <span className="exam-date text-end ">
                              {exam.start_date}
                            </span>
                          </div>
                          <div className="col-2 col-lg-2 col-md-12 col-sm-12 total-marks text-end pe-6"></div>
                          <div className="col-2 col-lg-2 col-md-12 col-sm-12 total-marks text-end pe-3">
                            {exam.marks} Marks
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col">
                            <strong>Duration:</strong> {exam.duration}
                          </div>
                          {/* <div className="col">
                            <strong>Instructor:</strong>{" "}
                            {exam.Full_names && Array.isArray(exam.Full_names)
                              ? exam.Full_names.join(", ")
                              : ""}
                          </div> */}
                          <div className="col">
                            <button
                              style={{
                                backgroundColor: "#0e2238",
                                color: "white",
                                border: "none",
                                padding: "15px 32px",
                                textAlign: "center",
                                textDecoration: "none",
                                display: "inline-block",
                                fontSize: "13px",
                                margin: "4px 2px",
                                cursor: "pointer",
                                borderRadius: "8px",
                              }}
                              onClick={() => openPopup(exam)}
                            >
                              View details
                            </button>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ))}
            </div>
            <div className="pagination d-flex justify-content-center mt-4">
              <ul className="pagination pagination-list justify-content-center">
                {Array.from(
                  { length: Math.ceil(filteredExams.length / itemsPerPage) },
                  (_, i) => (
                    <li key={i} className="page-item">
                      <button
                        onClick={() => paginate(i + 1)}
                        className="page-link"
                      >
                        {i + 1}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Exam details popup */}
      <Modal show={showPopup} onHide={closePopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Exam Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="formTitle">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter updated title"
                    value={updatedTitle}
                    onChange={(e) => handleInputChange(e, "title")}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formStartDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={updatedStartDate}
                    onChange={(e) => handleInputChange(e, "start_date")}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formDuration">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter updated duration"
                    value={updatedDuration}
                    onChange={(e) => handleInputChange(e, "duration")}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formMarks">
                  <Form.Label>Marks</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter updated marks"
                    value={updatedMarks}
                    onChange={(e) => handleInputChange(e, "marks")}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row mb-3 ">
              <div className="col">
                <strong>Questions:</strong>
                {/* <div className="d-flex justify-content-end mb-2">
                  {" "}
                  <button
                    className="btn btn-primary btn-sm "
                    onClick={() => setShowAddQuestionPopup(true)}
                  >
                    Add Question
                  </button>
                </div> */}
                <hr />
                <DataGrid
                  rows={questions.map((question, index) => ({
                    id: index,
                    index: index + 1,
                    question: question.questions,
                  }))}
                  columns={[
                    { field: "index", headerName: "Index", width: 100 },
                    { field: "question", headerName: "Question", width: 500 },
                    {
                      field: "actions",
                      headerName: "Actions",
                      width: 150,
                      align: "left",
                      renderCell: (params) => (
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() =>
                            deleteQuestion(
                              selectedExam.exam_id,
                              questions[params.row.id].questions_id
                            )
                          }
                        >
                          Delete
                        </button>
                      ),
                    },
                  ]}
                  pageSize={5}
                  autoHeight
                />
              </div>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={updateExamDetails}>
            Update
          </Button>
          <Button variant="danger" onClick={deleteExam}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add question popup */}
      <Modal
        show={showAddQuestionPopup}
        onHide={() => setShowAddQuestionPopup(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuestonBanLst />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddQuestionPopup(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddQuestionSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExamList;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";
// import { Accordion, Modal, Button, Form } from "react-bootstrap";
// import Sidebar from "./Sidebar";
// import AaasNav from "./AaasNav";
// import { DataGrid } from "@mui/x-data-grid";
// import { Link } from "react-router-dom";

// const ExamList = () => {
//   const [exams, setExams] = useState([]);
//   const [questions, setQuestions] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [selectedExam, setSelectedExam] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const [showQuestionPopup, setShowQuestionPopup] = useState(false);
//   const [updatedTitle, setUpdatedTitle] = useState("");
//   const [updatedStartDate, setUpdatedStartDate] = useState("");
//   const [updatedDuration, setUpdatedDuration] = useState("");
//   const [updatedInstructor, setUpdatedInstructor] = useState("");
//   const [updatedMarks, setUpdatedMarks] = useState("");
//   const [selectedQuestion, setSelectedQuestion] = useState(null);

//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(5);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.post(
//           "https://newaaas.kolhapurdakshin.com/fetch_questions/",
//           {}
//         );
//         const { exams } = response.data;
//         setExams(exams);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const openPopup = (exam) => {
//     setSelectedExam(exam);
//     setShowPopup(true);
//     setUpdatedTitle(exam.title);
//     setUpdatedStartDate(exam.start_date);
//     setUpdatedDuration(exam.duration);
//     setUpdatedInstructor(
//       exam.Full_names && Array.isArray(exam.Full_names)
//         ? exam.Full_names.join(", ")
//         : ""
//     );
//     setUpdatedMarks(exam.marks);
//     setQuestions(exam.questions);
//   };

//   const closePopup = () => {
//     setSelectedExam(null);
//     setShowPopup(false);
//   };

//   const updateExamDetails = () => {
//     closePopup();
//   };

//   const deleteExam = () => {
//     closePopup();
//   };

//   const openQuestionPopup = (question) => {
//     setSelectedQuestion(question);
//     setShowQuestionPopup(true);
//   };

//   const closeQuestionPopup = () => {
//     setSelectedQuestion(null);
//     setShowQuestionPopup(false);
//   };

//   const updateQuestionDetails = () => {
//     closeQuestionPopup();
//   };

//   const deleteQuestion = async (examId, questionId) => {
//     try {
//       await axios.delete("https://newaaas.kolhapurdakshin.com/delete_questions/", {
//         data: { exam_id: examId, questions_id: questionId },
//       });

//       const updatedQuestions = questions.filter(
//         (question) => question.questions_id !== questionId
//       );
//       setQuestions(updatedQuestions);

//       console.log("Question deleted successfully.");
//     } catch (error) {
//       console.error("Error deleting question:", error.response.data.error);
//     }
//   };
//   const redirectToQuestionBank = () => {
//     window.location.href = "/questonBanLst";
//   };
//   const handleInputChange = (event, field) => {
//     const value = event.target.value;
//     switch (field) {
//       case "title":
//         setUpdatedTitle(value);
//         break;
//       case "start_date":
//         setUpdatedStartDate(value);
//         break;
//       case "duration":
//         setUpdatedDuration(value);
//         break;
//       case "instructor":
//         setUpdatedInstructor(value);
//         break;
//       case "marks":
//         setUpdatedMarks(value);
//         break;
//       default:
//         break;
//     }
//   };

//   const filteredExams = exams.filter(
//     (exam) =>
//       exam.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       exam.start_date.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const indexOfLastExam = currentPage * itemsPerPage;
//   const indexOfFirstExam = indexOfLastExam - itemsPerPage;
//   const currentExams = filteredExams.slice(indexOfFirstExam, indexOfLastExam);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const searchBarStyle = {
//     display: "flex",
//     alignItems: "center",
//     border: "1px solid #ccc",
//     borderRadius: "4px",
//     overflow: "hidden",
//     width: "300px",
//     marginBottom: "20px",
//   };

//   const questionTextStyle = {
//     fontSize: "14px",
//   };

//   return (
//     <div>
//       <AaasNav />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
//           <div className="container mt-5">
//             <h2>All Scheduled Exam List</h2>
//             <div style={searchBarStyle}>
//               <FontAwesomeIcon
//                 icon={faSearch}
//                 style={{
//                   padding: "10px",
//                   backgroundColor: "#4d90fe",
//                   color: "white",
//                   border: "none",
//                   borderRadius: "0 4px 4px 0",
//                   cursor: "pointer",
//                 }}
//               />
//               <input
//                 type="text"
//                 placeholder="Search by Exam Title or Date"
//                 className="w-50"
//                 style={{
//                   flex: "1",
//                   padding: "8px",
//                   border: "none",
//                   borderRadius: "4px 0 0 4px",
//                   fontSize: "12px",
//                 }}
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//             <div className="card-container">
//               {filteredExams.map((exam) => (
//                 <div key={exam.exam_id} className="card">
//                   <Accordion>
//                     <Accordion.Item eventKey="0">
//                       <Accordion.Header>
//                         <div className="row w-100">
//                           <div className="col-lg-8 col-md-12 col-sm-12 exam-info ">
//                             <span
//                               className="exam-name text-start "
//                               style={{
//                                 fontWeight: "bold",
//                                 marginRight: "10px",
//                               }}
//                             >
//                               {exam.title}
//                             </span>
//                             <span className="exam-date text-end ">
//                               {exam.start_date}
//                             </span>
//                           </div>
//                           <div className="col-2 col-lg-2 col-md-12 col-sm-12 total-marks text-end pe-6"></div>
//                           <div className="col-2 col-lg-2 col-md-12 col-sm-12 total-marks text-end pe-3">
//                             {exam.marks} Marks
//                           </div>
//                         </div>
//                       </Accordion.Header>
//                       <Accordion.Body>
//                         <div className="row">
//                           <div className="col">
//                             <strong>Duration:</strong> {exam.duration}
//                           </div>
//                           <div className="col">
//                             <strong>Instructor:</strong>{" "}
//                             {exam.Full_names && Array.isArray(exam.Full_names)
//                               ? exam.Full_names.join(", ")
//                               : ""}
//                           </div>
//                           <div className="col">
//                             <button
//                               style={{
//                                 padding: "8px 16px",
//                                 fontSize: "14px",
//                                 backgroundColor: "#007BFF",
//                                 color: "#ffffff",
//                                 border: "none",
//                                 borderRadius: "3px",
//                                 cursor: "pointer",
//                                 boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//                                 transition: "background-color 0.3s ease-in-out",
//                               }}
//                               onClick={() => openPopup(exam)}
//                             >
//                               View details
//                             </button>
//                           </div>
//                         </div>
//                       </Accordion.Body>
//                     </Accordion.Item>
//                   </Accordion>
//                 </div>
//               ))}
//             </div>
//             <div className="pagination d-flex justify-content-center mt-4">
//               <ul className="pagination pagination-list justify-content-center">
//                 {Array.from(
//                   { length: Math.ceil(filteredExams.length / itemsPerPage) },
//                   (_, i) => (
//                     <li key={i} className="page-item">
//                       <button
//                         onClick={() => paginate(i + 1)}
//                         className="page-link"
//                       >
//                         {i + 1}
//                       </button>
//                     </li>
//                   )
//                 )}
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Exam details popup */}
//       <Modal show={showPopup} onHide={closePopup} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>View Exam Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <div className="row">
//               <div className="col-md-6">
//                 <Form.Group controlId="formTitle">
//                   <Form.Label>Title</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter updated title"
//                     value={updatedTitle}
//                     onChange={(e) => handleInputChange(e, "title")}
//                   />
//                 </Form.Group>
//               </div>
//               <div className="col-md-6">
//                 <Form.Group controlId="formStartDate">
//                   <Form.Label>Start Date</Form.Label>
//                   <Form.Control
//                     type="date"
//                     value={updatedStartDate}
//                     onChange={(e) => handleInputChange(e, "start_date")}
//                   />
//                 </Form.Group>
//               </div>
//               <div className="col-md-6">
//                 <Form.Group controlId="formDuration">
//                   <Form.Label>Duration</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter updated duration"
//                     value={updatedDuration}
//                     onChange={(e) => handleInputChange(e, "duration")}
//                   />
//                 </Form.Group>
//               </div>
//               <div className="col-md-6">
//                 <Form.Group controlId="formMarks">
//                   <Form.Label>Marks</Form.Label>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter updated marks"
//                     value={updatedMarks}
//                     onChange={(e) => handleInputChange(e, "marks")}
//                   />
//                 </Form.Group>
//               </div>
//             </div>
//             <div className="row mb-3 ">
//               <div className="col">
//                 <strong>Questions:</strong>
//                 <div className="d-flex justify-content-end mb-2">
//                   {" "}
//                   <button
//                     className="btn btn-primary btn-sm "
//                     //onClick={redirectToQuestionBank} // Call redirectToQuestionBank on click
//                   >
//                     Add Question
//                   </button>
//                 </div>
//                 <hr />
//                 <DataGrid
//                   rows={questions.map((question, index) => ({
//                     id: index,
//                     index: index + 1,
//                     question: question.questions,
//                   }))}
//                   columns={[
//                     { field: "index", headerName: "Index", width: 100 },
//                     { field: "question", headerName: "Question", width: 500 },
//                     {
//                       field: "actions",
//                       headerName: "Actions",
//                       width: 150,
//                       align: "left",
//                       renderCell: (params) => (
//                         <button
//                           className="btn btn-danger btn-sm"
//                           onClick={() =>
//                             deleteQuestion(
//                               selectedExam.exam_id,
//                               questions[params.row.id].questions_id
//                             )
//                           }
//                         >
//                           Delete
//                         </button>
//                       ),
//                     },
//                   ]}
//                   pageSize={5}
//                   autoHeight
//                 />
//               </div>
//             </div>
//           </Form>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" onClick={updateExamDetails}>
//             Update
//           </Button>
//           <Button variant="danger" onClick={deleteExam}>
//             Delete
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default ExamList;
// ExamList.js
// ExamList.js

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import Sidebar from "./Sidebar";
// import AaasNav from "./AaasNav";
// import axios from "axios";
// import { DataGrid } from "@mui/x-data-grid";
// import { Link } from "react-router-dom";

// const ExamList = () => {
//   const [exams, setExams] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.post(
//           "https://newaaas.kolhapurdakshin.com/fetch_questions/",
//           {}
//         );
//         const { exams } = response.data;
//         // Add unique id to each exam object
//         const examsWithId = exams.map((exam, index) => ({
//           ...exam,
//           id: index + 1, // You can use any unique identifier here
//         }));
//         setExams(examsWithId);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const columns = [
//     { field: "title", headerName: "Title", width: 200 },
//     { field: "start_date", headerName: "Start Date", width: 200 },
//     { field: "marks", headerName: "Marks", width: 150 },
//     {
//       field: "actions",
//       headerName: "Actions",
//       width: 150,
//       renderCell: (params) => (
//         <Link
//           to={`/vewDetals/${params.row.exam_id}`}
//           className="btn btn-primary btn-sm"
//         >
//           View details
//         </Link>
//       ),
//     },
//   ];

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <AaasNav />
//       <div className="wrapper">
//         <Sidebar />
//         <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
//           <div className="container mt-5">
//             <h2>All Scheduled Exam List</h2>
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 border: "1px solid #ccc",
//                 borderRadius: "4px",
//                 overflow: "hidden",
//                 width: "300px",
//                 marginBottom: "20px",
//               }}
//             >
//               <FontAwesomeIcon
//                 icon={faSearch}
//                 style={{
//                   padding: "10px",
//                   backgroundColor: "#4d90fe",
//                   color: "white",
//                   border: "none",
//                   borderRadius: "0 4px 4px 0",
//                   cursor: "pointer",
//                 }}
//               />
//               <input
//                 type="text"
//                 placeholder="Search by Exam Title or Date"
//                 className="w-50"
//                 style={{
//                   flex: "1",
//                   padding: "8px",
//                   border: "none",
//                   borderRadius: "4px 0 0 4px",
//                   fontSize: "12px",
//                 }}
//                 value={searchTerm}
//                 onChange={(e) => setSearchTerm(e.target.value)}
//               />
//             </div>
//             <div style={{ height: 400, width: "100%" }}>
//               <DataGrid rows={exams} columns={columns} pageSize={5} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ExamList;
