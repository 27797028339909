import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MonacoEditor from "react-monaco-editor"; // Assuming you've installed this library

export default function AdvancedCodeSandboxGenerator() {
  const [code, setCode] = useState("// Write your code here");

  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  const runCode = () => {
    try {
      eval(code);
    } catch (error) {
      console.error("Error executing code:", error);
    }
  };

  const formatCode = () => {
    // Implement code formatting logic here
    console.log("Code formatted");
  };

  const executeCode = () => {
    // Implement code execution logic here
    console.log("Code executed");
  };

  const saveCode = () => {
    // Implement code saving logic here
    console.log("Code saved");
  };

  // Add more functionality buttons here

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8">
          <div className="code-editor-container">
            <MonacoEditor
              width="100%"
              height="calc(100vh - 80px)" // Adjust as needed based on your layout
              language="javascript"
              theme="vs-dark"
              value={code}
              onChange={handleCodeChange}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="p-3">
            <h5>Instructions:</h5>
            <p>
              Write your code in the editor on the left and click "Run Code" to
              execute.
            </p>
            <button
              className="btn btn-primary btn-block mb-2"
              onClick={runCode}
            >
              Run Code
            </button>
            <button
              className="btn btn-secondary btn-block mb-2"
              onClick={formatCode}
            >
              Format Code
            </button>
            <button
              className="btn btn-success btn-block mb-2"
              onClick={executeCode}
            >
              Execute Code
            </button>
            <button className="btn btn-info btn-block mb-2" onClick={saveCode}>
              Save Code
            </button>
            {/* Add more buttons for additional functionality */}
          </div>
        </div>
      </div>
    </div>
  );
}
