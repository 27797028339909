import React, { useState, useEffect } from "react";
import { Card, Button, Modal, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";

const AdmnAdduser = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    password: "",
  });
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", variant: "" });
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    // Fetch roles from API
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    fetch("https://newaaas.kolhapurdakshin.com/fetch_role/")
      .then((response) => response.json())
      .then((data) => {
        setRoles(data.roles);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  };

  const handleAddClose = () => setShowAddModal(false);
  const handleAddShow = () => setShowAddModal(true);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(formData.password)) {
        setAlert({
          show: true,
          message:
            "Password must contain at least 8 characters, including one uppercase letter and one number.",
          variant: "danger",
        });
        return;
      }

      const requestData = {
        role_id: selectedRole,
        full_name: formData.full_name,
        email: formData.email,
        password: formData.password,
      };

      fetch("https://newaaas.kolhapurdakshin.com/create_user/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("User added:", data);
          setFormData({
            full_name: "",
            email: "",
            password: "",
          });
          setSelectedRole("");
          handleAddClose();
          Swal.fire({
            icon: "success",
            title: "User Added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
          setAlert({
            show: true,
            message: "Failed to add user. Please try again later.",
            variant: "danger",
          });
        });
    }
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div className="main p-3">
          <div className="text-center">
            <Card className="dashboard-card">
              <Card.Body>
                <Button
                  variant="primary"
                  className="mb-3"
                  onClick={handleAddShow}
                  style={{ marginRight: "10px" }}
                >
                  <FontAwesomeIcon icon={faUserPlus} /> Add User
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      {/* Add User Modal */}
      <Modal show={showAddModal} onHide={handleAddClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddUser}>
            <Form.Group controlId="formBasicRole">
              <Form.Label>Select Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                onChange={handleRoleChange}
                required
              >
                <option value="">Select Role</option>
                {roles.map((role) => (
                  <option key={role.role_id} value={role.role_id}>
                    {role.role_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {selectedRole && (
              <>
                <Form.Group controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="full_name"
                    value={formData.full_name}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Password must contain at least 8 characters, including one
                    uppercase letter and one number.
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}
            {alert.show && (
              <Alert variant={alert.variant}>{alert.message}</Alert>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={handleAddUser}>
            + Add {selectedRole}
          </Button>
          <Button variant="secondary" onClick={handleAddClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdmnAdduser;
