import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
export default function Sidebar({ isOpen }) {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    setIsExpanded(!isExpanded);
  }, [isOpen]);
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };
  const navigateToongongExam = () => {
    navigate("/scheduleExam/ongongExam");
  };
  const navigateToupcomngExam = () => {
    navigate("/upcomngExam");
  };
  const navigateToCompletedExam = () => {
    navigate("/CompletedExam");
  };
  const navigateTosExamLst = () => {
    navigate("/sExamLst");
  };
  const navigateTosQuestonLst = () => {
    navigate("/sQuestonLst");
  };
  const navigateToDashboard = () => {
    navigate("/logn/aaasStudentDashboard");
  };
  const navigateToProfle = () => {
    navigate("/ProfleStudent");
  };
  const navigateTosSuggestonFeedbac = () => {
    navigate("/sSuggestonFeedbac");
  };
  const navigateTonotfcaton = () => {
    navigate("/notfcaton");
  };

  return (
    <div className="">
      <aside
        id="sidebar"
        className="collapsed expand "
        style={{ height: "100%" }}
      >
        <div className="d-flex justify-content-between">
          <div className="sidebar-logo"></div>
          <button
            className="toggle-btn"
            type="button"
            onClick={() =>
              document.querySelector("#sidebar").classList.toggle("expand")
            }
            style={{ background: "transparent", color: "white" }}
          >
            <i className="lni lni-menu"></i>
          </button>
        </div>

        <ul className="sidebar-nav">
          <li className="sidebar-item">
            <a href="#" className="sidebar-link" onClick={navigateToDashboard}>
              <i className="lni lni-home"></i>
              <span> Dashboard</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a href="#" className="sidebar-link" onClick={navigateToProfle}>
              <i className="lni lni-user"></i>

              <span> Profile</span>
            </a>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#auth"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-protection"></i>
              <span>Exams</span>
            </a>
            <ul
              id="auth"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateToongongExam}
                >
                  Get Register for Exam
                </a>
              </li>
              <li className="sidebar-item text-start ps-4 ">
                <a
                  href="#"
                  className="sidebar-link"
                  onClick={navigateToupcomngExam}
                >
                  Upcoming Exam
                </a>
              </li>
              <li className="sidebar-item text-start ps-4 ">
                <a
                  href="#"
                  className="sidebar-link"
                  onClick={navigateToCompletedExam}
                >
                  completed - list and result
                </a>
              </li>
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown"
              data-bs-toggle="collapse"
              data-bs-target="#auths"
              aria-expanded="false"
              aria-controls="auth"
            >
              <i className="lni lni-question-circle"></i>
              <span>Result</span>
            </a>
            <ul
              id="auths"
              className="sidebar-dropdown list-unstyled collapse text-center"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link  "
                  onClick={navigateTosExamLst}
                >
                  All exams list and result{" "}
                </a>
              </li>
              {/* <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateTosQuestonLst}
                >
                  All Questions List
                </a>
              </li> */}
              <li className="sidebar-item text-start ps-4">
                <a
                  href="#"
                  className="sidebar-link "
                  onClick={navigateTosSuggestonFeedbac}
                >
                  Suggestions and Feedback
                </a>
              </li>
            </ul>
          </li>

          <li className="sidebar-item">
            <a href="#" className="sidebar-link" onClick={navigateTonotfcaton}>
              <i className="lni lni-checkmark-circle"></i>{" "}
              <span>Notification</span>
            </a>
          </li>
        </ul>
        <div className="sidebar-footer">
          <a href="#" className="sidebar-link">
            <i className="lni lni-exit"></i>
            <span>Sign-out</span>
          </a>
        </div>
      </aside>
    </div>
  );
}
// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
// export default function Sidebar({ isOpen }) {
//   const navigate = useNavigate();
//   const [isExpanded, setIsExpanded] = useState(true);

//   useEffect(() => {
//     const mediaQuery = window.matchMedia("(max-width: 768px)");
//     const handleMediaQueryChange = () => {
//       setIsExpanded(!mediaQuery.matches);
//     };
//     mediaQuery.addListener(handleMediaQueryChange);
//     handleMediaQueryChange(); // Call once to set initial state
//     return () => {
//       mediaQuery.removeListener(handleMediaQueryChange);
//     };
//   }, []);
//   // const toggleSidebar = () => {
//   //   setIsExpanded(!isExpanded);
//   // };
//   const navigateToongongExam = () => {
//     navigate("/scheduleExam/ongongExam");
//   };
//   const navigateToupcomngExam = () => {
//     navigate("/upcomngExam");
//   };
//   const navigateToCompletedExam = () => {
//     navigate("/CompletedExam");
//   };
//   const navigateTosExamLst = () => {
//     navigate("/sExamLst");
//   };
//   const navigateTosQuestonLst = () => {
//     navigate("/sQuestonLst");
//   };
//   const navigateToDashboard = () => {
//     navigate("/logn/aaasStudentDashboard");
//   };
//   const navigateToProfle = () => {
//     navigate("/ProfleStudent");
//   };
//   const navigateTosSuggestonFeedbac = () => {
//     navigate("/sSuggestonFeedbac");
//   };
//   const navigateTonotfcaton = () => {
//     navigate("/notfcaton");
//   };

//   return (
//     <div className="">
//       <aside
//         id="sidebar"
//         className={`collapsed ${isExpanded ? "expand" : ""}`}
//         style={{ height: "100%" }}
//       >
//         <div className="d-flex justify-content-between">
//           <div className="sidebar-logo"></div>
//           {/* <button
//             className="toggle-btn"
//             type="button"
//             onClick={() =>
//               document.querySelector("#sidebar").classList.toggle("expand")
//             }
//             style={{ background: "transparent", color: "white" }}
//           >
//             <i className="lni lni-menu"></i>
//           </button> */}
//         </div>

//         <ul className="sidebar-nav">
//           <li className="sidebar-item">
//             <a href="#" className="sidebar-link" onClick={navigateToDashboard}>
//               <i className="lni lni-home"></i>
//               <span> Dashboard</span>
//             </a>
//           </li>
//           <li className="sidebar-item">
//             <a href="#" className="sidebar-link" onClick={navigateToProfle}>
//               <i className="lni lni-user"></i>

//               <span> Profile</span>
//             </a>
//           </li>
//           <li className="sidebar-item">
//             <a
//               href="#"
//               className="sidebar-link collapsed has-dropdown"
//               data-bs-toggle="collapse"
//               data-bs-target="#auth"
//               aria-expanded="false"
//               aria-controls="auth"
//             >
//               <i className="lni lni-protection"></i>
//               <span>Exams</span>
//             </a>
//             <ul
//               id="auth"
//               className="sidebar-dropdown list-unstyled collapse text-center"
//               data-bs-parent="#sidebar"
//             >
//               <li className="sidebar-item text-start ps-4">
//                 <a
//                   href="#"
//                   className="sidebar-link "
//                   onClick={navigateToongongExam}
//                 >
//                   Get Register for Exam
//                 </a>
//               </li>
//               <li className="sidebar-item text-start ps-4 ">
//                 <a
//                   href="#"
//                   className="sidebar-link"
//                   onClick={navigateToupcomngExam}
//                 >
//                   Upcoming Exam
//                 </a>
//               </li>
//               <li className="sidebar-item text-start ps-4 ">
//                 <a
//                   href="#"
//                   className="sidebar-link"
//                   onClick={navigateToCompletedExam}
//                 >
//                   completed - list and result
//                 </a>
//               </li>
//             </ul>
//           </li>
//           <li className="sidebar-item">
//             <a
//               href="#"
//               className="sidebar-link collapsed has-dropdown"
//               data-bs-toggle="collapse"
//               data-bs-target="#auths"
//               aria-expanded="false"
//               aria-controls="auth"
//             >
//               <i className="lni lni-question-circle"></i>
//               <span>Result</span>
//             </a>
//             <ul
//               id="auths"
//               className="sidebar-dropdown list-unstyled collapse text-center"
//               data-bs-parent="#sidebar"
//             >
//               <li className="sidebar-item text-start ps-4">
//                 <a
//                   href="#"
//                   className="sidebar-link  "
//                   onClick={navigateTosExamLst}
//                 >
//                   All exams list and result{" "}
//                 </a>
//               </li>
//               {/* <li className="sidebar-item text-start ps-4">
//                 <a
//                   href="#"
//                   className="sidebar-link "
//                   onClick={navigateTosQuestonLst}
//                 >
//                   All Questions List
//                 </a>
//               </li> */}
//               <li className="sidebar-item text-start ps-4">
//                 <a
//                   href="#"
//                   className="sidebar-link "
//                   onClick={navigateTosSuggestonFeedbac}
//                 >
//                   Suggestions and Feedback
//                 </a>
//               </li>
//             </ul>
//           </li>

//           <li className="sidebar-item">
//             <a href="#" className="sidebar-link" onClick={navigateTonotfcaton}>
//               <i className="lni lni-checkmark-circle"></i>{" "}
//               <span>Notification</span>
//             </a>
//           </li>
//         </ul>
//         <div className="sidebar-footer">
//           <a href="#" className="sidebar-link">
//             <i className="lni lni-exit"></i>
//             <span>Sign-out</span>
//           </a>
//         </div>
//       </aside>
//     </div>
//   );
// }
