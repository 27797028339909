import React, { useState, useEffect } from "react";
import { Card, Button, Modal, Form, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";

const Access = () => {
  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div className="main p-3">Access Control</div>
      </div>
    </div>
  );
};

export default Access;
