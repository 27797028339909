import React, { useState, useEffect, useCallback } from "react";
import { Card, Spinner, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";
import { useLocation } from "react-router";

const QExamList = () => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [questions, setQuestions] = useState([]);
  const [filterButtons, setFilterButtons] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this to set items per page

  const location = useLocation();
  const examId = location.state?.examId;

  useEffect(() => {
    fetchFilterButtons();
    fetchQuestions();
  }, []);

  const fetchFilterButtons = useCallback(() => {
    fetch("https://newaaas.kolhapurdakshin.com/all_sub_category/")
      .then((response) => response.json())
      .then((data) => {
        const subCategories = data.sub_categories.map(
          (category) => category.sub_category
        );
        const initialSelectedQuestions = {};
        subCategories.forEach((subCategory) => {
          initialSelectedQuestions[subCategory] = [];
        });
        setFilterButtons(["All", ...subCategories]);
        setSelectedQuestions(initialSelectedQuestions);
      })
      .catch((error) => console.error("Error fetching filter buttons:", error));
  }, []);

  const fetchQuestions = useCallback(() => {
    setLoading(true);
    fetch("https://newaaas.kolhapurdakshin.com/fetch_question_list/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data.questions || []);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching questions:", error));
  }, []);

  const fetchQuestionsBySubcategory = useCallback((subCategoryId) => {
    setLoading(true);
    fetch("https://newaaas.kolhapurdakshin.com/sub_category_description/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sub_category_Id: subCategoryId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data.questions || []);
        setLoading(false);
      })
      .catch((error) =>
        console.error("Error fetching questions by subcategory:", error)
      );
  }, []);

  const handleFilterChange = useCallback(
    (filter, subCategoryId) => {
      setSelectedFilter(filter);
      if (filter !== "All") {
        fetchQuestionsBySubcategory(subCategoryId);
      } else {
        fetchQuestions();
      }
    },
    [fetchQuestions, fetchQuestionsBySubcategory]
  );

  const handleCheckboxChange = useCallback((questionId, subCategory) => {
    setSelectedQuestions((prevSelectedQuestions) => {
      const updatedSelectedQuestions = { ...prevSelectedQuestions };
      if (!updatedSelectedQuestions[subCategory]) {
        updatedSelectedQuestions[subCategory] = [];
      }
      const isSelected =
        updatedSelectedQuestions[subCategory].includes(questionId);
      if (isSelected) {
        updatedSelectedQuestions[subCategory] = updatedSelectedQuestions[
          subCategory
        ].filter((id) => id !== questionId);
      } else {
        updatedSelectedQuestions[subCategory].push(questionId);
      }
      return updatedSelectedQuestions;
    });
  }, []);

  const handleSubmit = () => {
    console.log("Selected questions:", selectedQuestions);
    console.log("Exam ID:", examId);

    const payload = {
      id: examId,
      questions_id: Object.values(selectedQuestions).flat(),
    };

    fetch("https://newaaas.kolhapurdakshin.com/exam_question_set/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Response from server:", data);
        if (data.message === "Questions added to the exam successfully.") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Selected questions submitted successfully!",
          });
        } else {
          console.error("Failed to add questions to the exam.");
        }
      })
      .catch((error) => console.error("Error submitting data:", error));
  };

  // Calculate current items based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = questions.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div
          className="main p-3"
          style={{
            flex: "1",
            overflow: "auto",
            background: "linear-gradient(to right, #33F2FF, #FF33F9, #035DEE)",
          }}
        >
          <div className="container mt-5">
            <h2 className="mb-4">All Question list manual</h2>
            <div className="filter-buttons mb-4">
              {filterButtons.map((button, index) => (
                <button
                  key={index}
                  className={`btn ${
                    selectedFilter === button ? "btn-dark" : "btn-light"
                  }`}
                  style={{ marginRight: "10px" }}
                  onClick={() => handleFilterChange(button, index)}
                >
                  {button}
                </button>
              ))}
            </div>

            {/* Table for displaying questions */}
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Description</th>
                    <th>Marks</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="3">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </td>
                    </tr>
                  ) : currentItems.length > 0 ? (
                    currentItems.map((question) => (
                      <tr key={question.questions_id}>
                        <td>
                          {/* <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedQuestions[
                              selectedFilter
                            ]?.includes(question.questions_id)}
                            onChange={() =>
                              handleCheckboxChange(
                                question.questions_id,
                                selectedFilter
                              )
                            }
                          /> */}
                        </td>
                        <td>{question.description}</td>
                        <td>{question.marks}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No questions found.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            {/* Pagination */}
            <ul className="pagination">
              {Array.from({
                length: Math.ceil(questions.length / itemsPerPage),
              }).map((_, index) => (
                <li key={index} className="page-item">
                  <button
                    onClick={() => paginate(index + 1)}
                    className="page-link"
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>

            <div className="selected-questions">
              {/* <h3>Selected Questions</h3> */}
              {/* {Object.keys(selectedQuestions).map((subCategory) => (
                <div key={subCategory}>
                  <h4>{subCategory}</h4>
                  <ul>
                    {(selectedQuestions[subCategory] || []).map(
                      (questionId) => {
                        const selectedQuestion = questions.find(
                          (q) => q.questions_id === questionId
                        );
                        return selectedQuestion ? (
                          <li key={questionId}>
                            {selectedQuestion.description}
                          </li>
                        ) : null;
                      }
                    )}
                  </ul>
                </div>
              ))} */}
              {/* <button className="btn btn-primary" onClick={handleSubmit}>
                Submit Selected Questions
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QExamList;
