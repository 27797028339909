import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SDSdebar from "../Pages/SDSdebar";
import AaasStudentNav from "../Pages/AaasStudentNav";
import { Row, Col, Accordion, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import axios from "axios";

const ExamCard = ({ exam, index }) => {
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: index * 100, // Adjust the delay for a staggered effect
  });

  const navigate = useNavigate();
  const [registeredExams, setRegisteredExams] = useState([]);

  const navigateToExamDetails = () => {
    navigate(`/scheduleExam/${exam.id}/examDetails`);
  };

  const [selectedItem, setSelectedItem] = useState("Not Started");

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  const handleRegister = async (examId) => {
    if (!registeredExams.includes(examId)) {
      try {
        const response = await axios.post(
          "https://newaaas.kolhapurdakshin.com/reg_exam/",
          {
            exam_id: examId,
            user_id: localStorage.getItem("user_id"),
          }
        );
        if (response.data.status === "success") {
          // Update state only if registration is successful
          setRegisteredExams([...registeredExams, examId]);
          // Provide feedback to the user about successful registration if needed
        } else {
          // Provide feedback to the user about registration failure if needed
          console.error(response.data.message);
        }
      } catch (error) {
        // Handle errors, e.g., network issues, server errors, etc.
        console.error("Registration failed:", error);
      }
    } else {
      // Provide feedback to the user that they are already registered for this exam
      console.log("You are already registered for this exam.");
    }
  };

  return (
    <animated.div key={exam.id} className="card" style={fadeIn}>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="row w-100">
              <div className="col-lg-8 col-md-12 col-sm-12 exam-info ">
                <span
                  className="exam-name text-start "
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {exam.title}
                </span>
                <span className="exam-date text-end ">{exam.start_date}</span>
              </div>
              <div className="col-2 col-lg-2 col-md-12 col-sm-12 total-marks text-end pe-6">
                <button
                  style={{
                    padding: "8px 16px",
                    fontSize: "14px",
                    backgroundColor: registeredExams.includes(exam.exam_id)
                      ? "#28a745"
                      : "#007BFF",
                    color: "#ffffff",
                    border: "none",
                    borderRadius: "3px",
                    cursor: "pointer",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease-in-out",
                  }}
                  onClick={() => handleRegister(exam.exam_id)}
                >
                  {registeredExams.includes(exam.exam_id)
                    ? "Awaiting Approval . . ."
                    : "Register"}
                </button>
              </div>
              <div className="col-2 col-lg-2 col-md-12 col-sm-12 total-marks text-end pe-3">
                {exam.duration} Marks
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col">
                <strong>Duration:</strong> {exam.duration}
              </div>
              <div className="col">
                <strong>Instructor:</strong> {exam.instructor_name}
              </div>
              <div className="col">
                {/* <button
                  style={{
                    padding: "8px 16px",
                    fontSize: "14px",
                    backgroundColor: "#007BFF",
                    color: "#ffffff",
                    border: "none",
                    borderRadius: "3px",
                    cursor: "pointer",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s ease-in-out",
                  }}
                  onClick={navigateToExamDetails}
                >
                  View Exam
                </button> */}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </animated.div>
  );
};

const ExamList = () => {
  const [exams, setExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchExams = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://newaaas.kolhapurdakshin.com/show_exam/",
        {
          params: {
            user_id: localStorage.getItem("user_id"),
          },
        }
      );
      if (response.data && response.data.exams) {
        setExams(response.data.exams);
      }
    } catch {
      setError();
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchExams();
  }, []);

  const searchBarStyle = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "4px",
    overflow: "hidden",
    width: "300px",
    marginBottom: "20px",
  };

  const searchIconStyle = {
    padding: "10px",
    backgroundColor: "#4d90fe",
    color: "white",
    border: "none",
    borderRadius: "0 4px 4px 0",
    cursor: "pointer",
  };

  const searchInputStyle = {
    flex: "1",
    padding: "8px", // Adjust the padding as needed
    border: "none",
    borderRadius: "4px 0 0 4px",
    fontSize: "12px", // Adjust the font size as needed
    // minWidth: "150px",
  };

  const filteredExams = exams.filter(
    (exam) =>
      exam.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      exam.start_date.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasStudentNav />
      <div className="wrapper">
        <SDSdebar />
        <div className="main p-3" style={{ flex: "1", overflow: "auto" }}>
          <div className="container mt-5">
            <h2>Get Register for Exam</h2>
            <div style={searchBarStyle}>
              <FontAwesomeIcon icon={faSearch} style={searchIconStyle} />
              <input
                type="text"
                placeholder="Search by Exam Name or Date"
                className="w-50"
                style={searchInputStyle}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            <div className="card-container">
              {filteredExams.map((exam, index) => (
                <ExamCard key={exam.exam_id} exam={exam} index={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamList;
