import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import AaasNav from "../Pages/AaasNav";
import Sidebar from "../Pages/Sidebar";
import { Button } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";

// Loader component
const Loader = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const ExamRequestApproval = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchApprovalData();
  }, []);

  const fetchApprovalData = async () => {
    setLoading(true);
    try {
      const user_id = localStorage.getItem("user_id");
      if (!user_id) {
        throw new Error("User ID not found in local storage");
      }
      const response = await fetch(
        `https://newaaas.kolhapurdakshin.com/approval_table/?user_id=${user_id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const rowsWithId = data.data.map((row, index) => ({
        ...row,
        id: index + 1,
      }));
      setRequests(rowsWithId);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong while fetching data!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(value)) {
        return prevSelectedRows.filter((rowId) => rowId !== value);
      } else {
        return [...prevSelectedRows, value];
      }
    });
  };

  const updateStatus = async () => {
    setLoading(true);
    try {
      const selectedRequests = requests.filter((request) =>
        selectedRows.includes(request.exam_id)
      );
      const payload = selectedRequests.map((request) => ({
        exam_id: request.exam_id,
        user_id: request.user_id,
      }));

      const response = await fetch(
        `https://newaaas.kolhapurdakshin.com/status/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update status");
      }

      const updatedRequests = requests.map((request) =>
        selectedRows.includes(request.exam_id)
          ? { ...request, status: "Selected" }
          : request
      );
      setRequests(updatedRequests);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Exam requests approved successfully.",
      });
    } catch (error) {
      console.error("Error updating status:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong while updating status!",
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "Select",
      width: 100,
      renderCell: (params) => (
        <input
          type="checkbox"
          value={params.row.exam_id}
          onChange={handleCheckboxChange}
        />
      ),
    },
    { field: "exam_id", headerName: "#", width: 70 },
    { field: "full_name", headerName: "Student Name", width: 200 },
    { field: "title", headerName: "Exam Name", width: 200 },
    { field: "status", headerName: "Status", width: 150 },
  ];

  return (
    <div style={{ marginTop: "60px" }}>
      <AaasNav />

      <div className="wrapper">
        <Sidebar />
        <div className="main p-3">
          <div className="text-start">
            <div style={{ marginBottom: "20px" }}>
              <h1 style={{ fontSize: "2.5rem", color: "#333" }}>
                Exam Approval Panel
              </h1>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#0e2238",
                  color: "white",
                  border: "none",
                  padding: "15px 32px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "13px",
                  margin: "4px 2px",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
                onClick={updateStatus}
                disabled={loading}
              >
                {loading ? "Approving..." : "Click to Approve"}
              </Button>
            </div>
            <div style={{ height: 400, width: "100%", position: "relative" }}>
              {loading ? (
                <Loader />
              ) : (
                <DataGrid
                  rows={requests}
                  columns={columns}
                  pageSize={5}
                  // checkboxSelection
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamRequestApproval;
