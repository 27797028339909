import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import AdmnNav from "../Pages/AdmnNav";
import AdmnSds from "../Pages/AdmnSds";
import { Card, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faQuestionCircle,
  faUsers,
  faPoll,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AdmnDashboard = () => {
  const navigate = useNavigate();

  // Mock data for demonstration
  const [examCount, setExamCount] = useState(0);
  const [quizCount, setQuizCount] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [subcategoryCount, setSubcategoryCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [instructorCount, setInstructorCount] = useState(0);
  const [resultCount, setResultCount] = useState(0);
  const [examReportCount, setExamReportCount] = useState(0);

  // Simulating fetching data from an API or database
  useEffect(() => {
    // Mock API call to fetch data
    // Example:
    // fetchExamCount().then((count) => setExamCount(count));
    // fetchQuizCount().then((count) => setQuizCount(count));
    // fetchQuestionCount().then((count) => setQuestionCount(count));
    // fetchCategoryCount().then((count) => setCategoryCount(count));
    // fetchSubcategoryCount().then((count) => setSubcategoryCount(count));
    // fetchAdminCount().then((count) => setAdminCount(count));
    // fetchStudentCount().then((count) => setStudentCount(count));
    // fetchInstructorCount().then((count) => setInstructorCount(count));
    // fetchResultCount().then((count) => setResultCount(count));
    // fetchExamReportCount().then((count) => setExamReportCount(count));

    // For demonstration, using mock data
    setExamCount(10);
    setQuizCount(50);
    setQuestionCount(1000);
    setCategoryCount(5);
    setSubcategoryCount(20);
    setAdminCount(5);
    setStudentCount(500);
    setInstructorCount(20);
    setResultCount(200);
    setExamReportCount(150);
  }, []);

  // Define routes for each section
  const navigateToExams = () => {
    navigate("/exams");
  };

  const navigateToQuestionBank = () => {
    navigate("/questionbank");
  };

  const navigateToUsers = () => {
    navigate("/users");
  };

  const navigateToResults = () => {
    navigate("/results");
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <AdmnNav />
      <div className="wrapper">
        <AdmnSds />
        <div className="main p-3">
          <div className="text-center">
            <Card className="dashboard-card">Admin Dashboard</Card>
          </div>
          <Row className="mt-4">
            <Col md={6}>
              <Card className="dashboard-item" onClick={navigateToQuestionBank}>
                <Card.Body>
                  <FontAwesomeIcon icon={faQuestionCircle} size="3x" />
                  <Card.Title className="mt-3">Question Bank</Card.Title>
                  <Card.Text>
                    Add Quiz: {quizCount} | Questions: {questionCount} |
                    Categories: {categoryCount} | Subcategories:{" "}
                    {subcategoryCount}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="dashboard-item" onClick={navigateToUsers}>
                <Card.Body>
                  <FontAwesomeIcon icon={faUsers} size="3x" />
                  <Card.Title className="mt-3">Users</Card.Title>
                  <Card.Text>
                    Admins: {adminCount} | Students: {studentCount} |
                    Instructors: {instructorCount}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <Card className="dashboard-item" onClick={navigateToExams}>
                <Card.Body>
                  <FontAwesomeIcon icon={faGraduationCap} size="3x" />
                  <Card.Title className="mt-3">Exams</Card.Title>
                  <Chart
                    options={{
                      chart: {
                        toolbar: {
                          show: false,
                        },
                      },
                    }}
                    series={[
                      {
                        name: "Exams",
                        data: [20, 15, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
                      },
                    ]}
                    type="line"
                    height={280}
                  />
                  <Card.Text>Total: {examCount}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="dashboard-item" onClick={navigateToResults}>
                <Card.Body>
                  <FontAwesomeIcon icon={faPoll} size="3x" />
                  <Card.Title className="mt-3">Results</Card.Title>
                  <Chart
                    options={{
                      chart: {
                        toolbar: {
                          show: false,
                        },
                      },
                    }}
                    series={[
                      {
                        name: "Results",
                        data: [
                          150, 140, 160, 180, 170, 190, 200, 210, 220, 230, 240,
                          250,
                        ],
                      },
                    ]}
                    type="bar"
                    height={280}
                  />
                  <Card.Text>Total: {resultCount}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AdmnDashboard;
